const fakeTiles = [
    {
        id: 1,
        position_x_desktop: 0,
        position_y_desktop: 0,
        width_desktop: 1,
        height_desktop: 1,
        position_x_mobile: 0, 
        position_y_mobile: 0, 
        width_mobile: 1,
        height_mobile: 1,
        color: '#427F9C',
        type: 'information',
        data: [
            { icon: 'building' },
            { value: '???' },
            { unit: 'Prises déployées' }
        ],
        isOptional: false
    },
    {
        id: 2,
        position_x_desktop: 1, 
        position_y_desktop: 0, 
        width_desktop: 1, 
        height_desktop: 1, 
        position_x_mobile: 1, 
        position_y_mobile: 0, 
        width_mobile: 1, 
        height_mobile: 1,
        color: '#427F9C',
        type: 'information',
        data: [
            { icon: 'raccordement' },
            { value: '???' },
            { unit: 'Prises raccordées' }
        ],
        isOptional: false
    },
    {
        id: 3,
        position_x_desktop: 0, 
        position_y_desktop: 1, 
        width_desktop: 2, 
        height_desktop: 2, 
        position_x_mobile: 1, 
        position_y_mobile: 4, 
        width_mobile: 1, 
        height_mobile: 1,
        color: '#66D5D3',
        type: 'link',
        data: [
            { title: 'Pas de chance' },
            { description: 'Il va falloir te connecter pour en savoir plus !' },
            { url: '#' },
            { is_private: false }
        ],
        isOptional: false
    },
    {
        id: 4,
        position_x_desktop: 0, 
        position_y_desktop: 3, 
        width_desktop: 1, 
        height_desktop: 1, 
        position_x_mobile: 0, 
        position_y_mobile: 6, 
        width_mobile: 1, 
        height_mobile: 1,
        color: '#F7BF66',
        type: 'link',
        data: [
            { title: 'Qu’est-ce que tu cherches ?' },
            { description: 'Un peu curieux non ?' },
            { url: '#' },
            { is_private: false }
        ],
        isOptional: false
    },
    {
        id: 5,
        position_x_desktop: 2, 
        position_y_desktop: 0, 
        width_desktop: 2, 
        height_desktop: 2, 
        position_x_mobile: 0, 
        position_y_mobile: 4, 
        width_mobile: 1, 
        height_mobile: 1,
        color: '#EF9700',
        type: 'link',
        data: [
            { title: 'Tu crois vraiment avoir trouvé ?' },
            { description: 'Toujours rien à voir ici...' },
            { url: '#' },
            { is_private: false }
        ],
        isOptional: false
    },
    {
        id: 6,
        position_x_desktop: 2, 
        position_y_desktop: 2, 
        width_desktop: 2, 
        height_desktop: 1, 
        position_x_mobile: 0, 
        position_y_mobile: 5, 
        width_mobile: 2, 
        height_mobile: 1,
        color: '#B3B2BD',
        type: 'link',
        data: [
            { title: 'F12 ne te sauvera pas' },
            { description: 'Pour avoir les infos, il te faudra te connecter !' },
            { url: '#' },
            { is_private: false }
        ],
        isOptional: false
    },
    {
        id: 7,
        position_x_desktop: 1, 
        position_y_desktop: 3, 
        width_desktop: 2, 
        height_desktop: 1, 
        position_x_mobile: 1, 
        position_y_mobile: 6, 
        width_mobile: 1, 
        height_mobile: 1,
        color: '#003B5E',
        type: 'link',
        data: [
            { title: 'Vraiment ?' },
            { description: 'Tu pensais que c\'était si simple ?' },
            { url: '#' },
            { is_private: false }
        ],
        isOptional: false
    },
    {
        id: 8,
        position_x_desktop: 3, 
        position_y_desktop: 3, 
        width_desktop: 1, 
        height_desktop: 1, 
        position_x_mobile: 0, 
        position_y_mobile: 7, 
        width_mobile: 1, 
        height_mobile: 1,
        color: '#DB0661',
        type: 'link',
        data: [
            { title: 'Mission impossible' },
            { description: 'Tu ne verras rien de plus ici...' },
            { url: '#' },
            { is_private: false }
        ],
        isOptional: false
    },
    {
        id: 9,
        position_x_desktop: 4, 
        position_y_desktop: 0, 
        width_desktop: 3, 
        height_desktop: 3, 
        position_x_mobile: 0, 
        position_y_mobile: 8, 
        width_mobile: 2, 
        height_mobile: 3,
        color: '#215D7D',
        type: 'link',
        data: [
            { title: 'Un indice ?' },
            { url: '#' },
            { is_private: false }
        ],
        isOptional: false
    },
    {
        id: 10,
        position_x_desktop: 4, 
        position_y_desktop: 3, 
        width_desktop: 1, 
        height_desktop: 1, 
        position_x_mobile: 1, 
        position_y_mobile: 7, 
        width_mobile: 1, 
        height_mobile: 1,
        color: '#99E2E0',
        type: 'link',
        data: [
            { title: 'Dernière chance' },
            { description: 'Mais il faut jouer selon les règles...' },
            { url: '#' },
            { is_private: false }
        ],
        isOptional: false
    },
    {
        id: 11,
        position_x_desktop: 5, 
        position_y_desktop: 3, 
        width_desktop: 2, 
        height_desktop: 1, 
        position_x_mobile: 0, 
        position_y_mobile: 11, 
        width_mobile: 2, 
        height_mobile: 1,
        color: '#CFCDD7',
        type: 'link',
        data: [
            { title: 'Easter egg ?' },
            { description: 'Peut-être, mais pas ici...' },
            { url: '#' },
            { is_private: false }
        ],
        isOptional: false
    },
    {
        id: 12,
        position_x_desktop: 7, 
        position_y_desktop: 0, 
        width_desktop: 3, 
        height_desktop: 4, 
        position_x_mobile: 0, 
        position_y_mobile: 1, 
        width_mobile: 2, 
        height_mobile: 3,
        color: '#E76CA1',
        type: 'button',
        data: [
            { title: 'Tu es presque...' },
            { button_title_1: 'Enigme ?' },
            { button_url_1: '#' },
            { button_title_2: 'Trouveras-tu ce qui se cache vraiment ici ?' },
            { button_url_2: '#' },
            { is_private: '1' }
        ],
        isOptional: false
    }
];

export default fakeTiles;