import React from 'react';

// IMAGES
import LogoLight from '../assets/images/Logo_AI_RVB_Couleur.png';
import LogoDark from '../assets/images/Logo_AI_RVB_Couleur_Blanc.png';
import LogoMobile from '../assets/images/Pastille_AI_RVB_Orange.png';

const Logo = ({ isDarkMode, handleLogoClick }) => {
  return (
    <div className="flex-shrink-0 flex items-center space-x-2 cursor-pointer" onClick={handleLogoClick}>
      {isDarkMode ? (
        <img src={LogoDark} alt="altitudeInfra Logo" className="hidden lg:block h-16" />
      ) : (
        <img src={LogoLight} alt="altitudeInfra Logo" className="hidden lg:block h-16" />
      )}
      <img src={LogoMobile} alt="altitudeInfra Logo Mobile" className="block lg:hidden h-16" />
    </div>
  );
};

export default Logo;