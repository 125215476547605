import React from 'react';

// SUBPAGES
import AnalyticsDashboard from '../pages/administration/dashboard/analyticsDashboard';
import ListUsers from '../pages/administration/users/listUsers';
import PermissionsUsers from '../pages/administration/users/permissionsUsers';
import HistoryAnnouncements from '../pages/administration/announcements/historyAnnouncements';
import ManagementAnnouncements from '../pages/administration/announcements/managementAnnouncements';
import Statistics from '../pages/administration/statistics';

const adminRoutes = [
    { path: 'dashboard/analytics', element: <AnalyticsDashboard /> },
    { path: 'users/list', element: <ListUsers /> },
    { path: 'users/permissions', element: <PermissionsUsers /> },
    { path: 'announcements/history', element: <HistoryAnnouncements /> },
    { path: 'announcements/management', element: <ManagementAnnouncements /> },
    { path: 'statistics', element: <Statistics /> }
];

export default adminRoutes;