import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from 'react-responsive';

// ICONS
import iconMap from "../utils/iconMap";

const Toast = ({ title, message, action, actionText, type, onClose }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const isMobile = useMediaQuery({ maxWidth: 640 });
    const [startY, setStartY] = useState(null);
    const toastRef = useRef(null);

    useEffect(() => {
        const toastElement = toastRef.current;
    
        if (toastElement) {
            const handleWheel = (event) => {
                event.preventDefault();
                event.stopPropagation();
            };
    
            toastElement.addEventListener('wheel', handleWheel, { passive: false });
    
            return () => {
                toastElement.removeEventListener('wheel', handleWheel);
            };
        }
    }, []);

    useEffect(() => {
        const htmlElement = document.documentElement;
        const observer = new MutationObserver(() => {
            setIsDarkMode(htmlElement.classList.contains('dark'));
        });

        observer.observe(htmlElement, { attributes: true, attributeFilter: ['class'] });

        // Initial check
        setIsDarkMode(htmlElement.classList.contains('dark'));

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            setTimeout(onClose, 500);
        }, 5000);
        return () => clearTimeout(timer);
    }, [onClose]);

    const handleTouchStart = (event) => {
        if (isMobile) {
            setStartY(event.touches[0].clientY);
        }
    };

    const handleTouchMove = (event) => {
        if (isMobile) {
            event.preventDefault();
        }
    };

    const handleTouchEnd = (event) => {
        if (isMobile && startY !== null) {
            const endY = event.changedTouches[0].clientY;
            if (startY - endY > 50) {
                setIsVisible(false);
                setTimeout(onClose, 300);
            }
            setStartY(null);
        }
    };

    const CrossIcon = iconMap['crossSmall'];

    const getIcon = (type) => {
        if (isDarkMode) {
            switch (type) {
                case 'success':
                    return iconMap['toastDarkSuccess'];
                case 'error':
                    return iconMap['toastDarkError'];
                case 'warning':
                    return iconMap['toastDarkWarning'];
                case 'info':
                    return iconMap['toastDarkInfo'];
                default:
                    return iconMap['toastDarkInfo'];
            }
        } else {
            switch (type) {
                case 'success':
                    return iconMap['toastLightSuccess'];
                case 'error':
                    return iconMap['toastLightError'];
                case 'warning':
                    return iconMap['toastLightWarning'];
                case 'info':
                    return iconMap['toastLightInfo'];
                default:
                    return iconMap['toastLightInfo'];
            }
        }
    };

    const getBackgroundClass = (type) => {
        switch (type) {
            case 'success':
                return 'bg-toast-light-background-success dark:bg-toast-dark-success';
            case 'error':
                return 'bg-toast-light-background-error dark:bg-toast-dark-error';
            case 'warning':
                return 'bg-toast-light-background-warning dark:bg-toast-dark-warning';
            case 'info':
                return 'bg-toast-light-background-info dark:bg-toast-dark-info';
            default:
                return 'bg-toast-light-background-info dark:bg-toast-dark-info';
        }
    };

    const getBorderClass = (type) => {
        switch (type) {
            case 'success':
                return 'border-toast-light-border-success dark:border-toast-dark-border-success';
            case 'error':
                return 'border-toast-light-border-error dark:border-toast-dark-border-error';
            case 'warning':
                return 'border-toast-light-border-warning dark:border-toast-dark-border-warning';
            case 'info':
                return 'border-toast-light-border-info dark:border-toast-dark-border-info';
            default:
                return 'border-toast-light-border-info dark:border-toast-dark-border-info';
        }
    };

    const Icon = getIcon(type);

    return (
        <div 
            ref={toastRef}
            className={`${isMobile ? 'w-full rounded-b-xl p-2 gap-1' : 'w-auto max-w-[600px] rounded-xl p-4 gap-4'} 
            shadow-lg border flex flex-col justify-between 
            ${getBackgroundClass(type)} ${getBorderClass(type)} 
            ${isVisible ? (isMobile ? 'animate-slide-down' : 'animate-slide-in-right') : (isMobile ? 'animate-slide-up' : 'animate-slide-out-right')}`}
            style={{ touchAction: 'none' }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div className="flex items-start gap-4 w-full">
                <Icon className="w-8 h-8 flex-shrink-0" />
                <div className="flex flex-col">
                    {title && <p className="poppins-bold text-sm md:text-lg text-noir dark:text-blanc">{title}</p>}
                    <p className="text-xs md:text-sm poppins-light text-noir dark:text-blanc">
                        {message}
                    </p>
                </div>
                {action && (
                    <button
                        onClick={action}
                        className="min-w-20 md:min-w-32 ml-4 px-2 md:px-4 py-2 bg-blanc dark:bg-noir-15 rounded-md border-gris border hover:bg-gris-20 dark:hover:bg-gris-50 focus:outline-none text-xs md:text-sm text-noir dark:text-blanc poppins-regular"
                    >
                        {actionText || 'Action'}
                    </button>
                )}
                {!isMobile && (
                    <button onClick={onClose} className="focus:outline-none ml-auto">
                        <CrossIcon className="w-6 h-6 md:w-8 md:h-8 fill-bleu dark:fill-blanc" />
                    </button>
                )}
            </div>
            {isMobile && (
                <button 
                    className="flex justify-center focus:outline-none py-2"
                    onClick={(e) => { e.stopPropagation(); setIsVisible(false); setTimeout(onClose, 300); }}
                >
                    <div className="w-28 h-1 bg-bleu dark:bg-blanc rounded-full"></div>
                </button>
            )}
        </div>
    );
}

export default Toast;