import React, { useRef, useEffect, useState } from 'react';

// UTILS
import iconMap from '../utils/iconMap';
import TooltipComponent from './tooltipComponent';

const SearchBar = ({ searchTerm, setSearchTerm, searchEngine, setSearchEngine, handleSearch, clearSearchBar, handleSearchEngineChange, isSearchMenuOpen, setIsSearchMenuOpen, isMenuOpen }) => {
  const searchMenuRef = useRef(null);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const logos = {
    Google: iconMap['google'],
    Bing: iconMap['bing'],
    QWant : iconMap['qwant'],
    Yahoo: iconMap['yahoo'],
    DuckDuckGo: iconMap['duckduckgo'],
    Ecosia: iconMap['ecosia'],
  };

  const CaretIcon = iconMap['caret'];
  const SearchIcon = iconMap['search'];
  const CrossIcon = iconMap['cross'];

  const getSearchEngineLogo = (engine) => logos[engine] || logos['Google'];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchMenuRef.current && !searchMenuRef.current.contains(event.target)) {
        setIsSearchMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [searchMenuRef, setIsSearchMenuOpen]);

  return (
    <div ref={searchMenuRef} className={`relative transition-transform duration-500 ease-in-out 2xl:w-[600px] w-80 sm:block hidden ${isMenuOpen ? 'translate-x-[-100px]' : 'translate-x-0'}`}>
      <span className="absolute inset-y-0 left-3 flex items-center cursor-pointer" 
        onClick={(event) => {
          event.stopPropagation();
          setIsSearchMenuOpen(!isSearchMenuOpen);
        }}
      >
        <TooltipComponent title="Changer de moteur de recherche" placement="bottom">
          <div className="relative flex items-center">
            <img src={getSearchEngineLogo(searchEngine)} alt="Search Engine Logo" className="h-6 w-6" />
            <CaretIcon
              className={`h-4 w-4 ml-1 fill-bleu dark:fill-blanc transition-transform ${
                isSearchMenuOpen ? 'rotate-0' : '-rotate-90'
              }`}
            />
          </div>
        </TooltipComponent>

        <div className="relative right-3 flex items-center">
          <div className={`h-10 mx-5 border ${isInputFocused ? 'border-emeraude' : 'border-bleu dark:border-blanc'}`}></div>
        </div>

        {isSearchMenuOpen && (
          <div className="absolute -left-2 top-full mt-2 w-48 bg-blanc dark:bg-noir text-noir dark:text-blanc rounded-lg shadow-lg">
            <ul>
              {['Google', 'Bing', 'QWant', 'Yahoo', 'DuckDuckGo', 'Ecosia'].map((engine) => (
                <li
                  key={engine}
                  className="cursor-pointer p-2 hover:bg-gris flex items-center"
                  onClick={() => handleSearchEngineChange(engine)}
                >
                  <img src={getSearchEngineLogo(engine)} alt={engine} className="h-6 w-6 mr-2" />
                  {engine}
                </li>
              ))}
            </ul>
          </div>
        )}
      </span>

      <input
        type="text"
        placeholder={`Rechercher avec ${searchEngine}...`}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        className="w-full pl-20 px-4 py-2 border rounded-full text-bleu dark:text-blanc bg-transparent dark:bg-transparent focus:outline-none focus:border-emeraude"
      />
      {searchTerm && (
        <>
          <span className="absolute inset-y-0 right-10 flex items-center pr-8 cursor-pointer" onClick={clearSearchBar}>
            <CrossIcon alt="Clear Search" className="h-6 w-6 fill-bleu dark:fill-blanc" />
          </span>
          <div className="absolute inset-y-0 right-8 flex items-center">
            <div className="h-6 border border-bleu dark:border-blanc mx-5"></div>
          </div>
        </>
      )}
      <span className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={handleSearch}>
        <SearchIcon alt="Search Icon" className="h-6 w-6 transform -scale-x-100 fill-bleu dark:fill-blanc" />
      </span>
    </div>
  );
};

export default SearchBar;