import React from 'react';

// COMPONENTS
import Folder from '../folderComponent';

const ButtonTile = ({ title, data, color }) => {

    const buttonTitles = Object.keys(data).filter(key => key.startsWith('button_title#'));
    const buttons = buttonTitles.map(titleKey => {
        const index = titleKey.split('#')[1];
        const buttonTitle = data[`button_title#${index}`] || 'Google';
        const buttonUrl = data[`button_url#${index}`] || 'https://www.google.com/';
        return {
            title: buttonTitle,
            url: buttonUrl
        };
    }).filter(button => button.title && button.url);

    return (
        <div className="p-4 rounded-lg tile-animate flex flex-col h-full overflow-hidden w-full">
            <h3 className="text-lg poppins-bold text-center text-blanc">{title}</h3>
            <div className="mt-6 flex flex-col gap-4 overflow-auto">
                {buttons.length > 0 ? (
                    buttons.map((button, index) => (
                        <div key={index} className="flex justify-start">
                            <Folder title={button.title} url={button.url} color={color} />
                        </div>
                    ))
                ) : (
                    <div>
                        <p>Aucun bouton disponible</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ButtonTile;