import React, { useState } from 'react';

// ROUTER
import { Routes, Route } from 'react-router-dom';
import adminRoutes from '../routes/adminRoutes';

// COMPONENTS
import SidebarMenu from '../components/administration/sidebarMenuComponent';
import Header from '../components/administration/headerComponent';

function Admin() {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarCollapsed((prev) => !prev);
    };

    return (
        <div className="flex min-h-screen text-blanc bg-admin-body-background bg-cover bg-fixed bg-no-repeat">
            {/* Sidebar */}
            <SidebarMenu
                isCollapsed={isSidebarCollapsed}
                setIsCollapsed={setIsSidebarCollapsed}
            />

            {/* Main Content Area */}
            <div className="flex-1 flex flex-col transition-all duration-300">
                {/* Header */}
                <Header
                    toggleSidebar={toggleSidebar}
                    isSidebarCollapsed={isSidebarCollapsed}
                />

                {/* Content */}
                <main className="flex-1 p-8 bg-admin-body bg-cover">
                    <Routes>
                        {adminRoutes.map(({ path, element }) => (
                            <Route key={path} path={path} element={element} />
                        ))}
                    </Routes>
                </main>
            </div>
        </div>
    );
}

export default Admin;