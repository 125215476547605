import { useState, useEffect, useContext } from 'react';
import { getUserADInfo, getUserBDDInfo, insertUser, updateUser } from '../services/userService';
import { upsertUserPreferences, getDefaultPreferences, getUserPreferences } from '../services/tileService';
import { insertConnectionStat } from '../services/statService';

// CONTEXT
import { ToastContext } from '../context/ToastContext';

export default function useUserData(email, isInternal, isExternal, isAdmin) {
    const { addToast } = useContext(ToastContext);
    const [loading, setLoading] = useState(true);
    const [userADInfo, setUserADInfo] = useState(null);
    const [userBDDInfo, setUserBDDInfo] = useState(null);
    const [preferences, setPreferences] = useState([]);
    const [tilesDefaultInterne, setTilesDefaultInterne] = useState([]);
    const [tilesDefaultExterne, setTilesDefaultExterne] = useState([]);

    const fetchDefaultPreferences = async (user_id) => {
        try {
            const response_default_preferences = await getDefaultPreferences(isExternal ? 'extern' : 'intern');
            let preferencesData = response_default_preferences.data?.[0]?.DEFAULT_PREFERENCES;

            if (!preferencesData) {
                console.error('Erreur lors de la récupération des préférences par défaut :', response_default_preferences);
                return;
            }

            if (typeof preferencesData === 'string') {
                try {
                    preferencesData = JSON.parse(preferencesData);
                } catch (error) {
                    console.error('Erreur lors de la récupération des préférences par défaut :', error);
                    return;
                }
            }

            if (preferencesData && isExternal) {
                setTilesDefaultExterne(JSON.stringify(preferencesData));
                await upsertUserPreferences(user_id, JSON.stringify(preferencesData));
            } else {
                setTilesDefaultInterne(JSON.stringify(preferencesData));
                await upsertUserPreferences(user_id, JSON.stringify(preferencesData));
            }
        } catch (error) {
            addToast(
                'Préférences par défaut',
                'Erreur lors de la récupération des préférences par défaut.',
                null,
                null,
                'error'
            );
            console.error('Erreur lors de la récupération des préférences par défaut :', error);
        }
    };

    /* const tilesDefaultInterne = [
        {position_x_desktop:0,position_y_desktop:0,width_desktop:1,height_desktop:1,position_x_mobile:0,position_y_mobile:0,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:1,color_id:7},
        {position_x_desktop:1,position_y_desktop:0,width_desktop:1,height_desktop:1,position_x_mobile:1,position_y_mobile:0,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:2,color_id:7},
        {position_x_desktop:4,position_y_desktop:2,width_desktop:2,height_desktop:1,position_x_mobile:0,position_y_mobile:8,width_mobile:2,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:3,color_id:11},
        {position_x_desktop:0,position_y_desktop:3,width_desktop:1,height_desktop:1,position_x_mobile:0,position_y_mobile:10,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:4,color_id:3},
        {position_x_desktop:2,position_y_desktop:0,width_desktop:2,height_desktop:1,position_x_mobile:0,position_y_mobile:7,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:5,color_id:1},
        {position_x_desktop:3,position_y_desktop:2,width_desktop:1,height_desktop:1,position_x_mobile:0,position_y_mobile:9,width_mobile:2,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:6,color_id:17},
        {position_x_desktop:1,position_y_desktop:3,width_desktop:2,height_desktop:1,position_x_mobile:1,position_y_mobile:10,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:7,color_id:5},
        {position_x_desktop:3,position_y_desktop:3,width_desktop:1,height_desktop:1,position_x_mobile:0,position_y_mobile:11,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:8,color_id:13},
        {position_x_desktop:7,position_y_desktop:0,width_desktop:3,height_desktop:4,position_x_mobile:0,position_y_mobile:1,width_mobile:2,height_mobile:3,min_height:2,max_height:6,min_width:3,max_width:4,tile_id:9,color_id:6},
        {position_x_desktop:4,position_y_desktop:3,width_desktop:1,height_desktop:1,position_x_mobile:1,position_y_mobile:11,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:10,color_id:12},
        {position_x_desktop:6,position_y_desktop:2,width_desktop:1,height_desktop:1,position_x_mobile:1,position_y_mobile:14,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:11,color_id:19},
        {position_x_desktop:4,position_y_desktop:0,width_desktop:3,height_desktop:2,position_x_mobile:0,position_y_mobile:12,width_mobile:2,height_mobile:2,min_height:2,max_height:4,min_width:2,max_width:3,tile_id:12,color_id:15},
        {position_x_desktop:5,position_y_desktop:3,width_desktop:2,height_desktop:1,position_x_mobile:0,position_y_mobile:14,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:13,color_id:1},
        {position_x_desktop:0,position_y_desktop:1,width_desktop:2,height_desktop:2,position_x_mobile:0,position_y_mobile:4,width_mobile:2,height_mobile:2,min_height:2,max_height:4,min_width:2,max_width:3,tile_id:15,color_id:14},
        {position_x_desktop:2,position_y_desktop:1,width_desktop:2,height_desktop:1,position_x_mobile:7,position_y_mobile:1,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:16,color_id:1},
        {position_x_desktop:2,position_y_desktop:2,width_desktop:1,height_desktop:1,position_x_mobile:0,position_y_mobile:6,width_mobile:2,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:17,color_id:6}
    ]; */

    /* const tilesDefaultExterne = [
        {position_x_desktop:2,position_y_desktop:0,width_desktop:1,height_desktop:1,position_x_mobile:0,position_y_mobile:0,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:1,color_id:7},
        {position_x_desktop:3,position_y_desktop:0,width_desktop:1,height_desktop:1,position_x_mobile:1,position_y_mobile:0,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:2,color_id:7},
        {position_x_desktop:2,position_y_desktop:1,width_desktop:2,height_desktop:2,position_x_mobile:0,position_y_mobile:2,width_mobile:2,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:3,color_id:11},
        {position_x_desktop:6,position_y_desktop:2,width_desktop:2,height_desktop:1,position_x_mobile:0,position_y_mobile:4,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:4,color_id:3},
        {position_x_desktop:4,position_y_desktop:0,width_desktop:2,height_desktop:1, position_x_mobile:0,position_y_mobile:1,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:5,color_id:1},
        {position_x_desktop:4,position_y_desktop:2,width_desktop:2,height_desktop:1, position_x_mobile:0,position_y_mobile:3,width_mobile:2,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:6,color_id:17},
        {position_x_desktop:2,position_y_desktop:3,width_desktop:2,height_desktop:1, position_x_mobile:1,position_y_mobile:4,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:7,color_id:5},
        {position_x_desktop:6,position_y_desktop:0,width_desktop:2,height_desktop:1, position_x_mobile:0,position_y_mobile:5,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:8,color_id:13},
        {position_x_desktop:6,position_y_desktop:1,width_desktop:2,height_desktop:1, position_x_mobile:1,position_y_mobile:5,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:10,color_id:12},
        {position_x_desktop:6,position_y_desktop:3,width_desktop:2,height_desktop:1, position_x_mobile:1,position_y_mobile:6,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:11,color_id:19},
        {position_x_desktop:4,position_y_desktop:3,width_desktop:2,height_desktop:1, position_x_mobile:0,position_y_mobile:6,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:13,color_id:1},
        {position_x_desktop:4,position_y_desktop:1,width_desktop:2,height_desktop:1, position_x_mobile:1,position_y_mobile:1,width_mobile:1,height_mobile:1,min_height:1,max_height:2,min_width:1,max_width:2,tile_id:16,color_id:1},  
    ]; */

    const openIRRIS = () => {
        window.open('https://irris.altitudeinfra.fr/#/tickets/create', '_blank');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response_user = await getUserADInfo(email);
                if (response_user) {
                    setUserADInfo(response_user);

                    const userExists = await getUserBDDInfo(response_user.data.ad_ai.uaiad_mail);

                    if (userExists.data.length === 0) {
                        let newUser = null;
                        try {
                            newUser = await insertUser(
                                response_user.data.ad_ai.uaiad_mail,
                                response_user.data.ad_ai.uaiad_physicaldeliveryofficename,
                                isExternal,
                                isAdmin,
                                'Google'
                            );
                            setUserBDDInfo(newUser);
                        } catch (error) {
                            addToast(
                                'Données utilisateur',
                                'Erreur lors de l\'insertion des données utilisateur.',
                                null,
                                null,
                                'error'
                            );
                            console.error('Erreur lors de l\'insertion des données utilisateur :', error);
                        }

                        try {
                            await insertConnectionStat(newUser.data[0].USR_ID);
                        } catch (error) {
                            addToast(
                                'Statistiques',
                                'Erreur lors de l\'insertion de la statistique de connexion.',
                                null,
                                null,
                                'error'
                            );
                            console.error('Erreur lors de l\'insertion de la statistique de connexion :', error);
                        }

                        await fetchDefaultPreferences(newUser.data[0].USR_ID);
                        
                        try {
                            const response_preferences = await getUserPreferences(newUser.data[0].USR_ID);
                            setPreferences(response_preferences.data);
                        } catch (error) {
                            addToast(
                                'Préférences utilisateur',
                                'Erreur lors de la récupération des préférences utilisateur.',
                                null,
                                null,
                                'error'
                            );
                            console.error('Erreur lors de la récupération des préférences utilisateur :', error);
                        }
                    } else {
                        setUserBDDInfo(userExists);

                        const lastConnection = new Date();
                        lastConnection.setHours(lastConnection.getHours() + 2);

                        await updateUser(response_user.data.ad_ai.uaiad_mail, {
                            last_connection_at: lastConnection,
                            is_external: isExternal,
                            is_admin: isAdmin,
                        });

                        let response_preferences = await getUserPreferences(userExists.data[0].USR_ID);

                        if (response_preferences.data.length === 0) {
                            await fetchDefaultPreferences(userExists.data[0].USR_ID);
                            response_preferences = await getUserPreferences(userExists.data[0].USR_ID);
                        }

                        setPreferences(response_preferences.data);
                    }
                } else {
                    throw new Error('Utilisateur introuvable dans Active Directory.');
                }
            } catch (error) {
                addToast(
                    'Données utilisateur',
                    error.message || 'Erreur lors de la récupération des données utilisateur.',
                    () => openIRRIS(),
                    'Ouvrir un ticket',
                    'error'
                );
                console.error('Erreur lors de la récupération des données utilisateur :', error);
            } finally {
                setLoading(false);
            }
        };

        if (email && (isInternal || isExternal || isAdmin)) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [email, isInternal, isExternal, isAdmin]);

    return { userADInfo, userBDDInfo, preferences, loading };
}