import React, { useState, useEffect } from 'react';

// COMPONENTS
import Breadcrumb from '../../../components/administration/breadcrumbComponent';
import Card from '../../../components/administration/cardComponent';
import GlobalChart from '../../../components/administration/globalChartComponent';

// CONTAINERS
import NewUserChartContainer from './containers/NewUserChartContainer';

const AnalyticsDashboard = () => {

    const donutChartData = {
        type: 'donut',
        categories: ['Google', 'Bing', 'QWant', 'Yahoo', 'DuckDuckGo', 'Ecosia'],
        series: [174, 10, 10, 10, 10, 10],
        colors: ['#DB0661', '#00BBBA', '#F4AB33', '#775DD0', '#FF6C00', '#FFD700'],
    };

    const barChartData = {
        type: 'bar',
        categories: ['Clicks', 'Likes', 'Upvotes'],
        series: [{ name: 'Visitors', data: [2589, 6748, 9842] }],
    };

    return (
        <div>
            {/* Breadcrumb */}
            <Breadcrumb iconName="home" title="Dashboard" currentPage="Analyses" />

            {/* Grid Layout */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            </div>
        </div>
    );
};

export default AnalyticsDashboard;