import * as users from '../../api/administration/usersApi';

export async function getUsersBDDInfo() {
    try {
        const response = await users.getUsersInfo();
        return response;
    } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur :', error);
        throw error;
    }
}