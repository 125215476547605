import * as authenticate from '../api/authentificationApi';

// Fonction pour obtenir un cookie par son nom
export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

// Fonction pour définir un cookie
export function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
}

// Vérifie le cookie local pour l'email encodé
export async function checkLocalCookie() {
    const encodedEmail = getCookie('encodedEmail');
    if (encodedEmail) {
        try {
            const response = await authenticate.decodeEmail(encodedEmail);
            if (response.data) {
                return response.data;
            }
        } catch (error) {
            console.error("Erreur lors du décodage de l'email :", error);
            throw new Error('Erreur lors du décodage de l\'email.');
        }
    }
    return null;
}

// Vérifie le SSO
export async function checkSSO() {
    try {
        const response = await fetch('https://bes-sso.altitudeinfra.fr/api/zhhkam_ajax_check', {
            method: 'GET',
            credentials: 'include',
        });
        const data = await response.text();
        if (data !== 'nok') {
            const encodeResponse = await authenticate.encodeEmail(data);
            const encodedEmail = encodeResponse.data;
            setCookie('encodedEmail', encodedEmail, 365);
            return data;
        }
    } catch (error) {
        console.error('Erreur lors de la vérification SSO :', error);
        throw new Error('Erreur lors de la vérification SSO.');
    }
    return null;
}

// Fonction pour gérer la connexion
export async function login(email, password) {
    try {
        const response = await authenticate.sendAuthentification(email, password);
        if (response.data === 'ok') {
            const encodedResponse = await authenticate.encodeEmail(email);
            const encodedEmail = encodedResponse.data;
            setCookie('encodedEmail', encodedEmail, 365);
            return email;
        } else if (response.data === 'nok') {
            return 'nok';
        } else {
            return null;
        }
    } catch (error) {
        console.error("Erreur lors de l'authentification :", error);
    }
}

// Fonction pour obtenir les groupes de sécurité
export async function getSecurityGroups(email) {
    try {
        const response = await authenticate.getSecurityGroups(email);
        return response.data;
    } catch (error) {
        console.error("Erreur lors de la récupération des groupes de sécurité :", error);
        return null;
    }
}