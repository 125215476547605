export function formatTileData(tile) {
    const formattedData = {};

    tile.data.forEach(item => {
        Object.keys(item).forEach(key => {
            formattedData[key] = item[key];
        });
    });
  
    return {
        ...tile,
        data: formattedData
    };
}