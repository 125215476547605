import { ReactComponent as BuildingIcon } from '../assets/icons/building.svg';
import { ReactComponent as RaccordementIcon } from '../assets/icons/raccordement.svg';

import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/cross.svg';
import { ReactComponent as CrossSmallIcon } from '../assets/icons/cross-small.svg';
import GoogleLogo from '../assets/icons/google.svg';
import BingLogo from '../assets/icons/bing.svg';
import QWantLogo from '../assets/icons/qwant.svg';
import YahooLogo from '../assets/icons/yahoo.svg';
import DuckDuckGoLogo from '../assets/icons/duckduckgo.svg';
import EcosiaLogo from '../assets/icons/ecosia.svg';

import { ReactComponent as ExitIcon } from '../assets/icons/exit.svg';
import { ReactComponent as SunIcon } from '../assets/icons/sun.svg';
import { ReactComponent as MoonIcon } from '../assets/icons/moon.svg';
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg';
import { ReactComponent as FranceIcon } from '../assets/icons/france.svg';
import { ReactComponent as EnglishIcon } from '../assets/icons/english.svg';
import { ReactComponent as AdminIcon } from '../assets/icons/admin.svg';

import { ReactComponent as EyeIcon } from '../assets/icons/eye.svg';
import { ReactComponent as EyeCrossedIcon } from '../assets/icons/eye-crossed.svg';

import { ReactComponent as FolderIcon } from '../assets/icons/folder.svg';

import { ReactComponent as WifiExclamationIcon } from '../assets/icons/wifi-exclamation.svg';
import { ReactComponent as ReseauAIBreakIcon } from '../assets/icons/reseau_ai_break.svg';

import { ReactComponent as ToastLightSuccessIcon } from '../assets/icons/toast_light_sucess.svg';
import { ReactComponent as ToastLightErrorIcon } from '../assets/icons/toast_light_error.svg';
import { ReactComponent as ToastLightWarningIcon } from '../assets/icons/toast_light_warning.svg';
import { ReactComponent as ToastLightInfoIcon } from '../assets/icons/toast_light_informative.svg';
import { ReactComponent as ToastDarkSuccessIcon } from '../assets/icons/toast_dark_sucess.svg';
import { ReactComponent as ToastDarkErrorIcon } from '../assets/icons/toast_dark_error.svg';
import { ReactComponent as ToastDarkWarningIcon } from '../assets/icons/toast_dark_warning.svg';
import { ReactComponent as ToastDarkInfoIcon } from '../assets/icons/toast_dark_informative.svg';

import { ReactComponent as EmptyInBoxIcon } from '../assets/icons/empty-inbox.svg';
import { ReactComponent as EmptyCalendarIcon } from '../assets/icons/empty-calendar.svg';

import { ReactComponent as CommunicationIcon } from '../assets/icons/communication.svg';
import { ReactComponent as RessourcesHumainesIcon } from '../assets/icons/rh.svg';
import { ReactComponent as PlaceDesProjetsIcon } from '../assets/icons/place_des_projets.svg';

import { ReactComponent as CommentInfoIcon } from '../assets/icons/comment-info.svg';
import { ReactComponent as CaretIcon } from '../assets/icons/caret.svg';

import { ReactComponent as MenuBurgerIcon } from '../assets/icons/menu-burger.svg';
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as UserIcon } from '../assets/icons/user.svg';
import { ReactComponent as MegaphoneIcon } from '../assets/icons/megaphone.svg';
import { ReactComponent as ExitAltIcon } from '../assets/icons/exit-alt.svg';
import { ReactComponent as AngleSmallIcon } from '../assets/icons/angle-small.svg';
import { ReactComponent as MoreIcon } from '../assets/icons/more.svg';

import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg';

import { ReactComponent as HeadbandNewIcon } from '../assets/icons/headband_new.svg';

const iconMap = {
    building: BuildingIcon,
    raccordement: RaccordementIcon,
    search: SearchIcon,
    cross: CrossIcon,
    crossSmall: CrossSmallIcon,
    google: GoogleLogo,
    bing: BingLogo,
    qwant: QWantLogo,
    yahoo: YahooLogo,
    duckduckgo: DuckDuckGoLogo,
    ecosia: EcosiaLogo,
    exit: ExitIcon,
    sun: SunIcon,
    moon: MoonIcon,
    edit: EditIcon,
    france: FranceIcon,
    english: EnglishIcon,
    admin: AdminIcon,
    eye: EyeIcon,
    eyeCrossed: EyeCrossedIcon,
    folder: FolderIcon,
    wifiExclamation: WifiExclamationIcon,
    reseauAIBreak: ReseauAIBreakIcon,
    toastLightSuccess: ToastLightSuccessIcon,
    toastLightError: ToastLightErrorIcon,
    toastLightWarning: ToastLightWarningIcon,
    toastLightInfo: ToastLightInfoIcon,
    toastDarkSuccess: ToastDarkSuccessIcon,
    toastDarkError: ToastDarkErrorIcon,
    toastDarkWarning: ToastDarkWarningIcon,
    toastDarkInfo: ToastDarkInfoIcon,
    emptyInBox: EmptyInBoxIcon,
    emptyCalendar: EmptyCalendarIcon,
    communication: CommunicationIcon,
    ressourcesHumaines: RessourcesHumainesIcon,
    placeDesProjets: PlaceDesProjetsIcon,
    commentInfo: CommentInfoIcon,
    caret: CaretIcon,
    menuBurger: MenuBurgerIcon,
    home: HomeIcon,
    user: UserIcon,
    megaphone: MegaphoneIcon,
    exitAlt: ExitAltIcon,
    angleSmall: AngleSmallIcon,
    more: MoreIcon,
    trash: TrashIcon,
    headbandNew: HeadbandNewIcon,
};

export default iconMap;