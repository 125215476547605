import React, { useState, useEffect } from 'react';

// CONTEXT

// COMPONENTS
import TooltipComponent from './tooltipComponent';
import AnimatedNumber from './AnimatedNumberComponent';

function CountdownComponent({ key, title, info, date }) {
    const [timeLeft, setTimeLeft] = useState({});
    const targetDate = new Date(date).getTime();

    const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const difference = targetDate - now;

        if (difference <= 0) {
            return setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({
            days: String(days).padStart(2, '0'),
            hours: String(hours).padStart(2, '0'),
            minutes: String(minutes).padStart(2, '0'),
            seconds: String(seconds).padStart(2, '0'),
        });
    };

    useEffect(() => {
        calculateTimeLeft();
        const interval = setInterval(() => {
            calculateTimeLeft();
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    return (
        <TooltipComponent title={info}>
            <div className="p-2 mt-2 w-full text-center bg-blanc-70 text-noir dark:bg-noir-70 dark:text-blanc rounded-2xl">
                <h3 className="text-sm poppins-bold">{title}</h3>
                <div className="poppins-semibold flex flex-wrap justify-center mt-2 space-x-5 md:space-x-2">
                    {/* Jours */}
                    <div className="flex flex-col items-center w-8 md:w-10">
                        <div className="digit-container p-2 text-sm lg:text-base h-8 md:h-10 w-full flex items-center justify-center">
                            <AnimatedNumber value={timeLeft.days} />
                        </div>
                        <span className="poppins-light text-xxs mt-2">Jours</span>
                    </div>
                    {/* Heures */}
                    <div className="flex flex-col items-center w-8 md:w-10">
                        <div className="digit-container p-2 text-sm lg:text-base h-8 md:h-10 w-full flex items-center justify-center">
                            <AnimatedNumber value={timeLeft.hours} />
                        </div>
                        <span className="poppins-light text-xxs mt-2">Heures</span>
                    </div>
                    {/* Minutes */}
                    <div className="flex flex-col items-center w-8 md:w-10">
                        <div className="digit-container p-2 text-sm lg:text-base h-8 md:h-10 w-full flex items-center justify-center">
                            <AnimatedNumber value={timeLeft.minutes} />
                        </div>
                        <span className="poppins-light text-xxs mt-2">Minutes</span>
                    </div>
                    {/* Secondes */}
                    <div className="flex flex-col items-center w-8 md:w-10">
                        <div className="digit-container p-2 text-sm lg:text-base h-8 md:h-10 w-full flex items-center justify-center">
                            <AnimatedNumber value={timeLeft.seconds} />
                        </div>
                        <span className="poppins-light text-xxs mt-2">Secondes</span>
                    </div>
                </div>
            </div>
        </TooltipComponent>
    );
};

export default CountdownComponent;