import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

// UTILS
import iconMap from '../utils/iconMap';

const InfoBubble = () => {
    const [isOpen, setIsOpen] = useState(false);    
    const [showSteps, setShowSteps] = useState(false);
    const bubbleRef = useRef(null);
    const isMobile = useMediaQuery({ maxWidth: 640 });

    const toggleInfo = () => {
        if (isOpen) return;
        setIsOpen(!isOpen);
        if (showSteps) setShowSteps(false);
    };

    const toggleSteps = () => {
        setShowSteps(!showSteps);
    };

    const handleClickOutside = (event) => {
        if (bubbleRef.current && !bubbleRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const CommentInfoIcon = iconMap['commentInfo'];
    const CrossIcon = iconMap['crossSmall'];

    return (
        <>
        {!isMobile && (
            <div className="fixed bottom-4 right-4 flex flex-col items-end">
                <button
                    onClick={toggleInfo}
                    className={`${
                        isOpen ? `w-3/4 ${showSteps ? 'h-64' : 'h-24'} rounded-lg` : 'w-16 h-16 rounded-full'
                    } p-4 bg-emeraude text-white flex items-center justify-center shadow-lg focus:outline-none transition-all duration-300 ease-in-out overflow-hidden`}
                    style={{ 
                        transformOrigin: 'right bottom',
                        transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out',
                    }}
                    ref={bubbleRef}
                >
                    {!isOpen && <CommentInfoIcon className="w-8 h-8 fill-blanc" />}
                    {isOpen && (
                        <div className="relative grid grid-cols-[1fr_auto_auto] gap-4 items-start w-full p-2">
                            {/* Div pour le texte */}
                            <div className="text-base text-left whitespace-normal overflow-hidden">
                                <p>
                                    Si vous rencontrez un problème quelconque ou avez une suggestion, veuillez ouvrir un ticket IRRIS.
                                </p>
                            </div>

                            {/* Div pour le lien et le bouton */}
                            <div className="flex flex-col items-start space-y-1">
                                <a
                                    href="https://irris.altitudeinfra.fr/#/tickets/create/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="px-2 py-1 bg-blanc dark:bg-noir-15 rounded-md border border-gris hover:bg-gris-20 dark:hover:bg-gris-50 focus:outline-none text-xs md:text-sm text-noir dark:text-blanc poppins-regular"
                                >
                                    Ouvrir un ticket IRRIS
                                </a>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleSteps();
                                    }}
                                    className="text-sm text-blanc underline focus:outline-none text-left"
                                >
                                    Comment faire ?
                                </button>
                            </div>

                            {/* Div pour le bouton de fermeture */}
                            <div className="flex justify-end">
                                <button onClick={() => setIsOpen(false)} className="focus:outline-none">
                                    <CrossIcon className="w-6 h-6 md:w-8 md:h-8 fill-blanc" />
                                </button>
                            </div>

                            {/* Affichage des étapes */}
                            {showSteps && (
                                <div className="col-span-3 mt-2 text-sm bg-white rounded p-2 text-left">
                                    <ol className="list-decimal list-inside space-y-1">
                                        <li>Cliquer sur le bouton "Ouvrir un ticket IRRIS" au-dessus.</li>
                                        <li>Sélectionner "Type de ticket" : DSI Helpdesk.</li>
                                        <li>Sélectionner "Catégorie" : Application.</li>
                                        <li>Sélectionner "Sous-catégorie" : Assistance logiciel ou Problème Logiciel.</li>
                                        <li>Choisir "Application concernée" : Hub Altitude Infra.</li>
                                    </ol>
                                </div>
                            )}
                        </div>
                    )}
                </button>
            </div>
        )}
        </>
    );
};

export default InfoBubble;