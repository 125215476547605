import React from 'react';

// UTILS
import iconMap from '../../utils/iconMap';

const Breadcrumb = ({ iconName, title, currentPage }) => {
    const Icon = iconMap[iconName];

    return (
        <div className="flex items-center space-x-4 text-admin-breadcrumb-title mb-6">
            <div className="text-lg poppins-semibold">
                {title}
            </div>
            <div className="border-l border-gray-500 pl-4">
                <nav aria-label="breadcrumb">
                    <ol className="flex items-center space-x-2 text-sm">
                        <li>
                            <Icon className="w-3 h-3 fill-blanc" />
                        </li>
                        <li className="text-admin-breadcrumb-title poppins-medium">
                            {currentPage}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    );
};

export default Breadcrumb;