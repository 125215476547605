import React from "react";

// UTILS
import iconMap from "../utils/iconMap";

const Folder = ({ title, url, color }) => {
    console.log(title, url, color);
    const FolderIcon = iconMap["folder"];

    const getIcon = (title) => {
        if (title === "Communication") {
            return iconMap["communication"];
        } else if (title === "Ressources Humaines") {
            return iconMap["ressourcesHumaines"];
        } else if (title === "Place des projets") {
            console.log(iconMap["placeDesProjets"]);
            return iconMap["placeDesProjets"];
        }
    };

    const IconComponent = getIcon(title);

    return (
        <a className="flex items-center gap-4" href={url} target="_blank" rel="noopener noreferrer">
            {/* Folder Icon & Icon */}
            <div className="relative">
                <FolderIcon className="w-10 h-10 fill-[#F3F5F7] dark:fill-noir-70" />
                <IconComponent
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-6 h-6"
                    style={{ fill: color }}
                />
            </div>
            {/* Title */}
            <div>
                <p className="text-sm poppins-bold text-blanc">
                    {title}
                </p>
            </div>
        </a>
    );
}

export default Folder;