import React, { useState, useEffect } from 'react';

// UTILS
import iconMap from '../utils/iconMap';

// COMPONENTS
import TooltipComponent from './tooltipComponent';

const Menu = ({ onLogout, onToggleDarkMode, onModifyInterface, onChangeLanguage, isDarkMode, isAdmin, language, profileWidth }) => {
    const [visible, setVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [animationDirection, setAnimationDirection] = useState(null);

    useEffect(() => {
        setVisible(true);
    }, []);

    const handleToggleDarkMode = () => {
        setIsAnimating(true);
        setAnimationDirection(isDarkMode ? 'light' : 'dark');

        setTimeout(() => {
        onToggleDarkMode();
        setIsAnimating(false);
        setAnimationDirection(null);
        }, 500);
    };

    const handleGoAdmin = () => {
        window.location.href = '/admin';
    };

    const SunIcon = iconMap['sun'];
    const MoonIcon = iconMap['moon'];

    const menuItems = [
        { id: 'edit', text: 'Modifier l\'interface', icon: 'edit', onClick: onModifyInterface },
        isAdmin && { id: 'admin', text: 'Administration', icon: 'admin', onClick: handleGoAdmin },
        //{ id: 'language', text: language === 'fr' ? 'Changer en Anglais' : 'Changer en Français', icon: language === 'fr' ? English : France, onClick: onChangeLanguage },
        //{ id: 'logout', text: 'Se déconnecter', icon: 'exit', onClick: onLogout },
    ];

    const delayClasses = ['delay-200', 'delay-300', 'delay-500'];

    return (
        <div className="absolute top-1/2 transform -translate-y-1/2 flex items-center md:space-x-4" style={{ right: `calc(${profileWidth}px + 50px)` }} >
            {/* Dark mode toggle */}
            <li 
                className={`px-4 py-2 text-bleu dark:text-blanc cursor-pointer text-center list-none transform transition-all duration-300 ease-in-out ${visible ? 'translate-x-0 opacity-100' : 'translate-x-20 opacity-0'} delay-100`}
                onClick={handleToggleDarkMode}
            >
                <TooltipComponent title={isDarkMode ? 'Mode Jour' : 'Mode Nuit'}>
                    <div className="relative w-6 h-6 md:h-8 md:w-8 mx-auto">
                        {/* Moon Icon */}
                        <MoonIcon
                            alt="Mode Jour"
                            className={`absolute w-6 h-6 md:h-8 md:w-8 fill-bleu dark:fill-blanc
                                ${((!isDarkMode && !isAnimating) || (isAnimating && animationDirection === 'light')) ? 'opacity-100' : 'opacity-0'}
                                ${isAnimating ? (animationDirection === 'dark' ? 'animate-sun-set' : animationDirection === 'light' ? 'animate-sun-rise' : '') : ''}
                            `}
                        />
                        {/* Sun Icon */}
                        <SunIcon
                            alt="Mode Nuit"
                            className={`absolute w-6 h-6 md:h-8 md:w-8 fill-bleu dark:fill-blanc
                                ${((isDarkMode && !isAnimating) || (isAnimating && animationDirection === 'dark')) ? 'opacity-100' : 'opacity-0'}
                                ${isAnimating ? (animationDirection === 'dark' ? 'animate-moon-rise' : animationDirection === 'light' ? 'animate-moon-set' : '') : ''}
                            `}
                        />
                    </div>
                </TooltipComponent>
            </li>

            {menuItems.map((item, index) => {
                const IconComponent = iconMap[item.icon];

                return(
                    <li 
                        key={index}
                        className={`px-4 py-2 text-bleu dark:text-blanc cursor-pointer text-center list-none transform transition-all duration-300 ease-in-out ${visible ? 'translate-x-0 opacity-100' : 'translate-x-20 opacity-0'} ${delayClasses[index]}`}
                        onClick={item.onClick}
                    >
                        <TooltipComponent title={item.text}>
                            {IconComponent && <IconComponent className="w-6 h-6 md:h-8 md:w-8 fill-bleu dark:fill-blanc" />}
                        </TooltipComponent>
                    </li>
                );
            })}
        </div>
    );
};

export default Menu;