import { useEffect } from 'react';

const checkForUpdates = () => {
  useEffect(() => {
    const fetchManifest = async () => {
      try {
        const response = await fetch('/manifest.json', { cache: 'no-store' });
        const manifest = await response.json();
        const latestVersion = manifest.version;
        const currentVersion = localStorage.getItem('appVersion');

        if (currentVersion && currentVersion !== latestVersion || !currentVersion) {
            localStorage.setItem('appVersion', latestVersion);
            window.location.reload(true); // Force le rechargement
        } else {
            localStorage.setItem('appVersion', latestVersion);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification des mises à jour :', error);
      }
    };

    fetchManifest();
  }, []);
};

export default checkForUpdates;