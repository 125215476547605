import axios from 'axios';

// Crée une instance axios avec des configurations par défaut
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_BACK}`,
    },
});

apiClient.interceptors.response.use(
    response => response,
    error => {
        handleApiError(error);
        return Promise.reject(error);
    }
);

const handleApiError = (error) => {
    if (error.response) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);

        // Vérification spécifique pour une erreur liée à la base de données
        if (error.response.data === 'Database connection error') {
            console.error('Erreur de connexion à la base de données. Veuillez réessayer plus tard.');
        }
    } else {
        console.error('Error Message:', error.message);
    }
};

export default apiClient;