import React, { useRef, useEffect } from 'react';

const Profile = ({ fullName, email, setIsMenuOpen, isMenuOpen, setProfileWidth }) => {
  const profileRef = useRef(null);

  useEffect(() => {
    if (profileRef.current) {
      setProfileWidth(profileRef.current.offsetWidth);
    }
  }, [isMenuOpen]);

  return (
    <div ref={profileRef} className="flex-shrink-0 relative flex items-center ml-4 cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)}>
      <p className="hidden lg:block text-2xl poppins-bold pr-4 text-bleu dark:text-blanc">{fullName}</p>
      <div className="relative">
        <img src={`${process.env.REACT_APP_URL_SSO}/avatar/${email}`} alt="Avatar" className="h-16 w-16 rounded-full" />
      </div>
    </div>
  );
};

export default Profile;