import React, { useState, useEffect } from 'react';

// MATERIAL UI
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const CustomTooltip = styled(({ className, isDarkMode, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} TransitionComponent={Zoom} />
))(({ theme, isDarkMode }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: isDarkMode ? '#1A1A1A' : '#FFFFFF',                       // Couleur de fond
        color: isDarkMode ? '#FFFFFF' : '#1A1A1A',                                 // Couleur du texte
        fontSize: theme.typography.pxToRem(12),                                    // Taille de la police
        fontFamily: 'Poppins, sans-serif !important',                              // Police
        padding: '5px 7.5px',                                                      // Padding personnalisé
        borderRadius: '5px',                                                       // Bordure arrondie
        //border: `1px solid ${isDarkMode ? '#FFFFFF' : '#003B5E'}`,                 // Bordure
    },
}));

const TooltipComponent = ({ title, placement, children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const htmlElement = document.documentElement;
        const observer = new MutationObserver(() => {
            setIsDarkMode(htmlElement.classList.contains('dark'));
        });

        observer.observe(htmlElement, { attributes: true, attributeFilter: ['class'] });

        // Initial check
        setIsDarkMode(htmlElement.classList.contains('dark'));

        return () => observer.disconnect();
    }, []);

    return (
        <CustomTooltip title={title} placement={placement} isDarkMode={isDarkMode}>
            {children}
        </CustomTooltip>
    );
};

export default TooltipComponent;