import React, { useEffect, useState } from 'react';

const Loader = () => {
  const [widgetStyles, setWidgetStyles] = useState({});
  const [lastTemplateIndex, setLastTemplateIndex] = useState(-1);

  const widgets = [
    { className: 'w1', colorClass: 'bg-orange' },
    { className: 'w2', colorClass: 'bg-bleu' },
    { className: 'w3', colorClass: 'bg-emeraude' },
    { className: 'w4', colorClass: 'bg-framboise' },
    { className: 'w5', colorClass: 'bg-emeraude' },
    { className: 'w6', colorClass: 'bg-emeraude' },
    { className: 'w7', colorClass: 'bg-emeraude' },
    { className: 'w8', colorClass: 'bg-emeraude' },
    { className: 'w9', colorClass: 'bg-orange' },
    { className: 'w10', colorClass: 'bg-bleu' },
    { className: 'w11', colorClass: 'bg-orange' },
    { className: 'w12', colorClass: 'bg-bleu' },
    { className: 'w13', colorClass: 'bg-framboise' },
    { className: 'w14', colorClass: 'bg-orange' },
    { className: 'w15', colorClass: 'bg-framboise' },
    { className: 'w16', colorClass: 'bg-emeraude' },
    { className: 'w17', colorClass: 'bg-framboise' },
    { className: 'w18', colorClass: 'bg-bleu' },
  ];

  const templates = [
    // Template 1
    {
      w1: { col: 1, row: 1, colSpan: 1, rowSpan: 2 },
      w2: { col: 2, row: 1, colSpan: 2, rowSpan: 1 },
      w3: { col: 2, row: 2, colSpan: 2, rowSpan: 2 },
      w4: { col: 6, row: 1, colSpan: 1, rowSpan: 1 },
      w6: { col: 5, row: 1, colSpan: 1, rowSpan: 2 },
      w17: { col: 4, row: 2, colSpan: 1, rowSpan: 1 },
      w9: { col: 4, row: 1, colSpan: 1, rowSpan: 1 },
      w13: { col: 1, row: 3, colSpan: 1, rowSpan: 1 },
      w11: { col: 4, row: 3, colSpan: 2, rowSpan: 1 },
      w18: { col: 6, row: 2, colSpan: 1, rowSpan: 2 },
    },
    // Template 2
    {
      w1: { col: 1, row: 1, colSpan: 1, rowSpan: 1 },
      w2: { col: 2, row: 1, colSpan: 2, rowSpan: 2 },
      w4: { col: 1, row: 2, colSpan: 1, rowSpan: 2 },
      w5: { col: 4, row: 1, colSpan: 1, rowSpan: 1 },
      w8: { col: 3, row: 3, colSpan: 1, rowSpan: 1 },
      w9: { col: 4, row: 2, colSpan: 1, rowSpan: 2 },
      w11: { col: 2, row: 3, colSpan: 1, rowSpan: 1 },
      w13: { col: 5, row: 1, colSpan: 2, rowSpan: 1 },
      w16: { col: 5, row: 2, colSpan: 2, rowSpan: 2 },
    },
    // Template 3
    {
      w1: { col: 1, row: 1, colSpan: 1, rowSpan: 3 },
      w2: { col: 2, row: 1, colSpan: 2, rowSpan: 1 },
      w6: { col: 2, row: 2, colSpan: 2, rowSpan: 1 },
      w9: { col: 2, row: 3, colSpan: 1, rowSpan: 1 },
      w13: { col: 3, row: 3, colSpan: 1, rowSpan: 1 },
      w16: { col: 4, row: 1, colSpan: 3, rowSpan: 3 },
    },
    // Template 4
    {
      w1: { col: 1, row: 1, colSpan: 3, rowSpan: 1 },
      w2: { col: 1, row: 2, colSpan: 1, rowSpan: 2 },
      w4: { col: 2, row: 2, colSpan: 2, rowSpan: 1 },
      w6: { col: 2, row: 3, colSpan: 2, rowSpan: 1 },
      w8: { col: 4, row: 1, colSpan: 2, rowSpan: 1 },
      w9: { col: 4, row: 2, colSpan: 1, rowSpan: 2 },
      w13: { col: 5, row: 2, colSpan: 1, rowSpan: 2 },
      w16: { col: 6, row: 1, colSpan: 1, rowSpan: 3 },
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(randomizeTemplate, 1000);
    randomizeTemplate();

    return () => clearInterval(intervalId);
  }, []);

  const getPosition = (col, row) => {
    const padding = 5;
    const gap = 5;
    const cellSize = 27.5;

    const left = padding + (col - 1) * (cellSize + gap);
    const top = padding + (row - 1) * (cellSize + gap);

    return { left, top };
  };

  const getSize = (colSpan, rowSpan) => {
    const gap = 5;
    const cellSize = 27.5;

    const width = colSpan * cellSize + (colSpan - 1) * gap;
    const height = rowSpan * cellSize + (rowSpan - 1) * gap;

    return { width, height };
  };

  const applyTemplate = (template) => {
    let newWidgetStyles = {};

    widgets.forEach((widget) => {
      newWidgetStyles[widget.className] = {
        width: '0px',
        height: '0px',
        left: '50%',
        top: '50%',
      };
    });

    Object.keys(template).forEach((widgetClass) => {
      const { col, row, colSpan, rowSpan } = template[widgetClass];
      const { left, top } = getPosition(col, row);
      const { width, height } = getSize(colSpan, rowSpan);

      newWidgetStyles[widgetClass] = {
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
      };
    });

    setWidgetStyles(newWidgetStyles);
  };

  const randomizeTemplate = () => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * templates.length);
    } while (randomIndex === lastTemplateIndex);

    setLastTemplateIndex(randomIndex);
    applyTemplate(templates[randomIndex]);
  };

  return (
    <div className="flex justify-center items-center h-screen bg-transparent">
      <div
        className="w-[200px] rounded-[10px] overflow-hidden flex flex-col"
        style={{ backgroundColor: '#DDDBE4' }}
      >
        <div
          className="header p-[10px]"
          style={{ backgroundColor: '#a8d5e2' }}
        ></div>
        <div
          className="content w-[200px] h-[102.5px] p-[5px] relative"
          style={{ backgroundColor: '#DDDBE4' }}
        >
          {widgets.map((widget, index) => (
            <div
              key={index}
              className={`widget ${widget.colorClass} absolute rounded-[5px] transition-all duration-[800ms] ease-in-out`}
              style={widgetStyles[widget.className]}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Loader;
