import React, { useContext } from 'react';

// CONTEXT
import { ToastContext } from '../../context/ToastContext';

// COMPONENTS
import TooltipComponent from '../tooltipComponent';

// SERVICES
import { insertTileStat } from '../../services/statService';

const LinkTile = ({ user_id, tile_id, title, desc, url, isPrivate, onPrivateClick }) => {
  const { addToast } = useContext(ToastContext);

  const handleClick = (e) => {
    if (isPrivate) {
      e.preventDefault();
      addToast('Connexion au réseau', 'Oups ! Il semble que vous ne soyez pas connecté au réseau de l\'entreprise 🔒', () => openFortiClient(), 'Activer le VPN', 'warning');
      if (onPrivateClick) {
        onPrivateClick();
      }
    } else {
      insertTileStat(user_id, tile_id);
    }
  };

  const openFortiClient = () => {
    window.location.href = 'forticlient://open';
  };

  return (
    <TooltipComponent title={desc} placement="bottom">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="w-full h-full p-4 rounded-lg flex flex-col justify-end"
        style={{ textDecoration: 'none' }}
        onClick={handleClick}
      >
        <h3 className="sm:text-sm text-xs poppins-bold text-blanc overflow-hidden text-ellipsis">{title}</h3>
        <p className="text-xs text-blanc overflow-hidden md:line-clamp-2 md:display-none" style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>{desc}</p>
      </a>
    </TooltipComponent>
  );
};

export default LinkTile;