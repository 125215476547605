import React, { useState, useEffect } from 'react';

// SERVICES
import { getUsersBDDInfo } from '../../../services/administration/usersService';
import { getUserPreferences } from '../../../services/tileService';

// COMPONENTS
import Breadcrumb from '../../../components/administration/breadcrumbComponent';
import Modal from '../../../components/modalComponent.js';
import Grid from '../../../components/gridComponent';

// UTILS
import iconMap from '../../../utils/iconMap';
import { MapPreferencesToTiles } from '../../../utils/mapPreferencesToTiles';

function ListUsers() {
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userTiles, setUserTiles] = useState([]);

    const logos = {
        Google: iconMap['google'],
        Bing: iconMap['bing'],
        QWant : iconMap['qwant'],
        Yahoo: iconMap['yahoo'],
        DuckDuckGo: iconMap['duckduckgo'],
    };

    const FRIcon = iconMap['france'];
    const ENIcon = iconMap['english'];

    const SunIcon = iconMap['sun'];
    const MoonIcon = iconMap['moon'];

    const EyeIcon = iconMap['eye'];

    useEffect(() => {
        getUsersBDDInfo()
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.error('Erreur lors de la récupération des utilisateurs :', error);
            });
    }, []);

    const handleOpenModal = async (user) => {
        setSelectedUser(user);

        try {
            const response = await getUserPreferences(user.USR_ID); 
            const tiles =  MapPreferencesToTiles(response.data);
            setUserTiles(tiles);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Erreur lors de la récupération des préférences de l'utilisateur:", error);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
        setUserTiles([]);
    };

    return (
        <div>
            <Breadcrumb iconName={'user'} title={'Utilisateurs'} currentPage={'Liste'} />
            <div
                className="space-y-4 overflow-y-auto"
                style={{ maxHeight: '700px', padding: '8px' }}
            >
                {users.map((user) => (
                    <div
                        key={user.USR_ID}
                        className="flex items-center bg-admin-card-background rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow"
                    >
                        <div className="flex-shrink-0 w-12 h-12">
                            <img src={`${process.env.REACT_APP_URL_SSO}/avatar/${user.USR_EMAILADDRESS}`} alt="Avatar" className="h-12 w-12 rounded-full" />
                        </div>
                        {/* Informations utilisateur */}
                        <div className="ml-4 flex-1">
                            <p className="font-semibold text-lg">{user.USR_EMAILADDRESS}</p>
                            <p className="text-sm text-gray-500 flex items-center">
                                {user.USR_AGENCY}
                                {user.USR_LANGUAGE === 'FR' ? (
                                    <FRIcon className="w-4 h-4 ml-4" />
                                ) : (
                                    <ENIcon className="w-4 h-4 ml-4" />
                                )}
                            </p>
                            <div className="flex items-center mt-1">
                                <img
                                    src={logos[user.USR_BROWSER]}
                                    alt={user.USR_BROWSER}
                                    className="w-5 h-5 mr-2"
                                />
                                <p className="text-sm text-gray-500">{user.USR_BROWSER}</p>
                            </div>
                            <p className="text-sm text-gray-500">
                                Dernière connexion : {user.USR_LAST_CONNECTION_AT}
                            </p>
                        </div>
                        <div className="mr-10" >                            
                            {/* Bouton Voir l'interface */}
                            <div 
                                onClick={() => handleOpenModal(user)}
                                className="flex group items-center text-sm cursor-pointer bg-bleu px-4 py-2 rounded-md hover:bg-emeraude transition-all ease-in-out duration-300"
                            >
                                <EyeIcon className="w-5 h-5 fill-blanc group-hover:mr-2 transition-all duration-300 ease-in-out"/>
                                <div className="inline-block overflow-hidden max-w-0 group-hover:max-w-xs transition-all duration-500 ease-in-out text-white whitespace-nowrap">
                                    Voir l'interface
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            {/* Mode sombre pastille */}
                            {user.USR_DARK_MODE ? (
                                <MoonIcon className="w-6 h-6 fill-blanc" />
                            ) : (
                                <SunIcon className="w-6 h-6 fill-blanc" />
                            )}
                            {/* Admin badge */}
                            {user.USR_IS_ADMIN && (
                                <span className="text-xs bg-bleu text-blanc px-2 py-1 rounded-full">
                                    Admin
                                </span>
                            )}
                            {/* Externe badge */}
                            {user.USR_IS_EXTERNAL ? (
                                <span className="text-xs bg-emeraude text-blanc px-2 py-1 rounded-full">
                                    Externe
                                </span>
                            ) : (
                                <span className="text-xs bg-framboise text-blanc px-2 py-1 rounded-full">
                                    Interne
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal */}
            {isModalOpen && (
                <Modal onClose={handleCloseModal}>
                    <div className="p-4">
                        <h2 className="text-xl font-semibold mb-4">Interface de {selectedUser?.USR_EMAILADDRESS}</h2>
                        {userTiles && userTiles.length > 0 ? (
                            <Grid tiles={userTiles} />
                        ) : (
                            <p>Aucune préférence trouvée pour cet utilisateur.</p>
                        )}
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default ListUsers;