import React, { useState, useEffect, useMemo } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

// UTILS
import { formatTileData } from '../utils/formatTileData';

// COMPONENTS
import InformationTile from './tiles/informationTileComponennt';
import LinkTile from './tiles/linkTileComponent';
import NotificationTile from './tiles/notificationTileComponent';
import ButtonTile from './tiles/buttonTileComponent';
import CountdownTile from './tiles/countdownTileComponent';
import TooltipComponent from './tooltipComponent';

// ICONS
import iconMap from '../utils/iconMap';

const ResponsiveGridLayout = WidthProvider(Responsive);

const breakpoints = { lg: 1600, md: 1300, xs: 1150, xxs: 640 };
const cols = { lg: 10, md: 10, xs: 10, xxs: 2 };
const margins = { lg: [30, 30], md: [20, 20], xs: [15, 15], xxs: [10, 10] };
const rowHeights = { lg: 150, md: 110, xs: 100, xxs: 150 };

const Grid = ({ user, tiles }) => {
  const [shakingTiles, setShakingTiles] = useState([]);
  const [isOnInternalNetwork, setIsOnInternalNetwork] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const [gridDimensions, setGridDimensions] = useState({ width: 1280, height: 750 });
  const ReseauAIBreakIcon = iconMap['reseauAIBreak'];
  const HeadbandNewIcon = iconMap['headbandNew'];

  const updateDimensions = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= breakpoints.lg) {
      setCurrentBreakpoint('lg');
      setGridDimensions({ width: 1830, height: gridDimensions.height });
    } else if (windowWidth >= breakpoints.md) {
      setCurrentBreakpoint('md');
      setGridDimensions({ width: 1320, height: gridDimensions.height });
    } else if (windowWidth >= breakpoints.xs) {
      setCurrentBreakpoint('xs');
      setGridDimensions({ width: 1165, height: gridDimensions.height });
    } else {
      setCurrentBreakpoint('xxs');
      setGridDimensions({ width: 330, height: gridDimensions.height });
    }
  };

  const calculateGridHeight = async () => {
    const isDesktop = currentBreakpoint === 'lg' || currentBreakpoint === 'md' || currentBreakpoint === 'xs';
    const maxRow = tiles.length > 0 
    ? Math.max(...tiles.map(tile => (isDesktop ? tile.position_y_desktop + tile.height_desktop : tile.position_y_mobile + tile.height_mobile)))
    : 1;
    const totalHeight = maxRow * rowHeights[currentBreakpoint] + (maxRow + 1) * (margins[currentBreakpoint][1] || 0);

    if (Number.isFinite(totalHeight) && totalHeight > 0) {
      await setGridDimensions(prev => ({ ...prev, height: totalHeight }));
    } else {
      await setGridDimensions(prev => ({ ...prev, height: 750 }));
    }
  };

  useEffect(() => {
    updateDimensions();
    calculateGridHeight();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [currentBreakpoint, tiles]);

  const generateResponsiveLayout = (tile) => ({
    lg: {
      i: tile.id.toString(),
      x: Math.floor(tile.position_x_desktop),
      y: Math.floor(tile.position_y_desktop),
      w: Math.max(1, Math.floor(tile.width_desktop)),
      h: Math.max(1, Math.floor(tile.height_desktop)),
      static: true,
    },
    md: {
      i: tile.id.toString(),
      x: Math.floor(tile.position_x_desktop),
      y: Math.floor(tile.position_y_desktop),
      w: Math.max(1, Math.floor(tile.width_desktop)),
      h: Math.max(1, Math.floor(tile.height_desktop)),
      static: true,
    },
    xs: {
      i: tile.id.toString(),
      x: Math.floor(tile.position_x_desktop),
      y: Math.floor(tile.position_y_desktop),
      w: Math.max(1, Math.floor(tile.width_desktop)),
      h: Math.max(1, Math.floor(tile.height_desktop)),
      static: true,
    },
    xxs: {
      i: tile.id.toString(),
      x: Math.floor(tile.position_x_mobile),
      y: Math.floor(tile.position_y_mobile),
      w: Math.max(1, Math.floor(tile.width_mobile)),
      h: Math.max(1, Math.floor(tile.height_mobile)),
      static: true,
    }
  });

  const layouts = useMemo(() => {
    const responsiveLayouts = {};
    tiles.forEach(tile => {
      const tileLayouts = generateResponsiveLayout(tile);
      Object.keys(tileLayouts).forEach(bp => {
        if (!responsiveLayouts[bp]) responsiveLayouts[bp] = [];
        responsiveLayouts[bp].push(tileLayouts[bp]);
      });
    });
    return responsiveLayouts;
  }, [tiles]);

  useEffect(() => {
    const checkNetworkConnectivity = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_INFRAGATE_URL}/api/check-network`, { method: 'GET' });
        if (response.ok) {
          setIsOnInternalNetwork(true);
        }
      } catch (error) {
        setIsOnInternalNetwork(false);
      }
    };
    checkNetworkConnectivity();
  }, []);

  const formattedTiles = useMemo(() => {
    return tiles.map(tile => formatTileData(tile));
  }, [tiles]);

  if (!tiles || tiles.length === 0) {
    return null;
  }

  const handleShake = (tileId) => {
    setShakingTiles((prev) => [...prev, tileId]);
    setTimeout(() => {
      setShakingTiles((prev) => prev.filter((id) => id !== tileId));
    }, 500);
  };

  const renderTileContent = (tile) => {
    switch (tile.type) {
      case 'information':
        return <InformationTile icon={tile.data.icon} value={tile.data.value} unit={tile.data.unit} hover={tile.data.hover} />;
      case 'link':
        return <LinkTile user_id={user?.userBDDInfo.data[0].USR_ID} tile_id={tile.id} title={tile.data.title} desc={tile.data.description} url={tile.data.url} isPrivate={tile.data.is_private === '1' && !isOnInternalNetwork} onPrivateClick={() => handleShake(tile.id)} />;
      case 'notification':
        return <NotificationTile user_id={user?.userBDDInfo.data[0].USR_ID} title={tile.data.title} url={tile.data.url} notif={tile.notification} isPrivate={tile.data.is_private === '1' && !isOnInternalNetwork} onPrivateClick={() => handleShake(tile.id)} />;
      case 'button':
        return <ButtonTile title={tile.data.title} data={tile.data} color={tile.color} />;
      case 'countdown':
        return <CountdownTile title={tile.data.title} event={tile.evenement} />;
      default:
        return <div>Erreur: Type de tuile inconnu</div>;
    }
  };

  return (
    <div style={{ width: gridDimensions.width, height: gridDimensions.height }}>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={breakpoints}
        cols={cols}
        rowHeight={rowHeights[currentBreakpoint]}
        margin={margins[currentBreakpoint]}
        isDraggable={false} // Désactive le déplacement
        isResizable={false} // Désactive la redimensionnement
        onBreakpointChange={(newBreakpoint) => {
          setCurrentBreakpoint(newBreakpoint);
          updateDimensions();
        }}
      >
        {formattedTiles.map(tile => (
          <div key={tile.id} className="relative rounded-lg">
            <div
              className={`flex justify-center items-center w-full h-full ${shakingTiles.includes(tile.id) ? 'animate-shake' : ''}`}
              style={{ borderRadius: '20px', backgroundColor: tile.color }}
            >
              {tile.data.is_private === '1' && !isOnInternalNetwork && (
                <div className="absolute top-2.5 right-2.5">
                  <TooltipComponent title={'Connexion VPN nécessaire'}>
                    <ReseauAIBreakIcon className="h-5 w-5 fill-blanc" />
                  </TooltipComponent>
                </div>
              )}
              {/* Affiche une icône de "nouveau" si la tuile a été créée il y a moins de 10 jours */}
              {tile.createdAt && new Date() - new Date(tile.createdAt) < 864000000 && (
                <div className="absolute top-1 left-1">
                  <HeadbandNewIcon className="size-12 lg:size-8 2xl:size-12 fill-blanc" />
                </div>
              )}
              {renderTileContent(tile)}
            </div>
          </div>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
};

export default Grid;