import React, { useState, useEffect, useContext } from 'react';

// COMPONENTS
import Logo from './logoComponent';
import SearchBar from './searchBarComponent';
import Menu from './menuComponent';
import Profile from './profileComponent';
import TooltipComponent from './tooltipComponent';

// API
import * as users from '../api/userApi';

// CONTEXT
import { ToastContext } from '../context/ToastContext';

// SERVICES
import { insertSearchStat } from '../services/statService';

const Header = (user) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [profileWidth, setProfileWidth] = useState(0);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [language, setLanguage] = useState('FR');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchEngine, setSearchEngine] = useState('Google');
    const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false);
    const { addToast } = useContext(ToastContext);

    useEffect(() => {
        if (user?.user?.userBDDInfo.data[0].USR_DARK_MODE !== undefined) {
            setIsDarkMode(user.user.userBDDInfo.data[0].USR_DARK_MODE);
        }
        if (user?.user?.userBDDInfo.data[0].USR_IS_ADMIN !== undefined) {
            setIsAdmin(user.user.userBDDInfo.data[0].USR_IS_ADMIN);
        }
        if (user?.user?.userBDDInfo.data[0].USR_BROWSER !== undefined) {
            setSearchEngine(user.user.userBDDInfo.data[0].USR_BROWSER);
        }
    }, [user]);

    const email = user?.user?.userBDDInfo.data[0].USR_EMAILADDRESS || '';
    const defaultBrowser = user?.user?.userBDDInfo.data[0].USR_BROWSER || 'Google';
    const fullName = user?.user?.userADInfo.data.ad_ai.uaiad_displayname;

    useEffect(() => {
        setSearchEngine(defaultBrowser);
    }, [defaultBrowser]);

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [isDarkMode]);

    const clearSearchBar = () => setSearchTerm('');

    const handleSearch = () => {
        if (!searchTerm) return;

        const searchUrls = {
            Google: `https://www.google.com/search?q=${encodeURIComponent(searchTerm)}`,
            Bing: `https://www.bing.com/search?q=${encodeURIComponent(searchTerm)}`,
            QWant: `https://www.qwant.com/?q=${encodeURIComponent(searchTerm)}`,
            Yahoo: `https://search.yahoo.com/search?p=${encodeURIComponent(searchTerm)}`,
            DuckDuckGo: `https://duckduckgo.com/?q=${encodeURIComponent(searchTerm)}`,
            Ecosia: `https://www.ecosia.org/search?q=${encodeURIComponent(searchTerm)}`,
        };

        window.open(searchUrls[searchEngine], '_blank');
        if (user.user.userBDDInfo.data[0].USR_ID) {
            insertSearchStat(user.user.userBDDInfo.data[0].USR_ID, searchEngine);
        }
        clearSearchBar();
    };

    const handleLogout = () => {
        console.log('Déconnexion');
    };

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
        updateUser(email, { dark_mode: !isDarkMode });
    };

    const handleChangeLanguage = () => {
        setLanguage((prevLang) => (prevLang === 'fr' ? 'en' : 'fr'));
        console.log(`Langue changée : ${language}`);
    };

    const handleEditInterface = () => {
        const messages = [
            'Cette fonctionnalité arrive bientôt ! 🎉',
            'Bientôt disponible ! 🚀',
            'Encore un peu de patience… ✨',
        ]
        const randomMessage = messages[Math.floor(Math.random() * messages.length)];
        addToast('Nouvelle fonctionnalité', randomMessage, '', '', 'info');
    };

    const updateUser = async (email, fieldsToUpdate) => {
        try {
            await users.updateUser(email, fieldsToUpdate);
        } catch (error) {
            console.error('Erreur lors de la mise à jour du navigateur :', error);
        }
    };

    const handleSearchEngineChange = (engine) => {
        setSearchEngine(engine);
        if (email) {
            updateUser(email, { browser: engine });
        }
        setIsSearchMenuOpen(false);
    };

    const handleLogoClick = () => {
        window.open('https://www.altitudeinfra.fr/', '_blank');
    };

    return (
        <header className="flex items-center justify-between h-36 py-4 px-6 bg-blanc dark:bg-noir relative z-50">
            {/* Logo */}
            <div className="flex-1 flex justify-start">
                <Logo isDarkMode={isDarkMode} handleLogoClick={handleLogoClick} />
            </div>

            {/* Barre de recherche */}
            <div className="flex-1 flex justify-center">
                <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchEngine={searchEngine}
                    setSearchEngine={setSearchEngine}
                    handleSearch={handleSearch}
                    clearSearchBar={clearSearchBar}
                    handleSearchEngineChange={handleSearchEngineChange}
                    isSearchMenuOpen={isSearchMenuOpen}
                    setIsSearchMenuOpen={setIsSearchMenuOpen}
                    isMenuOpen={isMenuOpen}
                />
            </div>

            {isMenuOpen && (
                <Menu
                    onLogout={handleLogout}
                    onToggleDarkMode={toggleDarkMode}
                    onModifyInterface={handleEditInterface}
                    onChangeLanguage={handleChangeLanguage}
                    isDarkMode={isDarkMode}
                    isAdmin={isAdmin}
                    language={language}
                    profileWidth={profileWidth}
                />
            )}

            {/* Utilisateur */}
            <div className="flex-1 flex justify-end">
                {email && (
                    <>
                        {isMenuOpen ? (
                            <TooltipComponent title="Fermer le menu" placement="bottom">
                                <div>
                                    <Profile fullName={fullName} email={email} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} setProfileWidth={setProfileWidth} />
                                </div>
                            </TooltipComponent>
                        ) : (
                            <TooltipComponent title="Ouvrir le menu" placement="bottom">
                                <div>
                                    <Profile fullName={fullName} email={email} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} setProfileWidth={setProfileWidth} />
                                </div>
                            </TooltipComponent>
                        )}
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;