import * as notification from '../api/notificationAPI';

export async function getAllNotifs() {
    try {
        const response = await notification.getAllNotifs();
        return response;
    } catch (error) {
        console.error('Erreur lors de la récupération des notifications :', error);
        throw error;
    }
}

export async function deleteNotif(notif_id) {
    try {
        const response = await notification.deleteNotif(notif_id);
        return response;
    } catch (error) {
        console.error('Erreur lors de la suppression de la notification :', error);
        throw error;
    }
}

export async function markAsRead(user_id, notif_id) {
    try {
        const response = await notification.markAsRead(user_id, notif_id);
        return response;
    } catch (error) {
        console.error('Erreur lors de la mise à jour de la notification :', error);
        throw error;
    }
}