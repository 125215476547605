import React from 'react';

// COMPONENTS
import CountdownComponent from '../countdownComponent';

// UTILS
import iconMap from '../../utils/iconMap';

const CountdownTile = ({ title, event })=> {
    let eventsArray = [];

    const EmptyCalendarIcon = iconMap.emptyCalendar;

    if (Array.isArray(event)) {
        if (typeof event[0] === 'string') {
            // event est un tableau contenant une chaîne JSON, on la parse
            try {
                eventsArray = JSON.parse(event[0]);
            } catch (error) {
                console.error('Erreur lors du parsing de la chaîne JSON:', error);
                eventsArray = [];
            }
        } else {
            // event est déjà un tableau d'objets
            eventsArray = event;
        }
    } else if (typeof event === 'string') {
        // event est une chaîne JSON, on la parse
        try {
            eventsArray = JSON.parse(event);
        } catch (error) {
            console.error('Erreur lors du parsing de la chaîne JSON:', error);
            eventsArray = [];
        }
    } else if (event) {
        // event est un objet unique, on le met dans un tableau
        eventsArray = [event];
    }

    const now = Date.now();
    eventsArray = eventsArray.filter(e => {
        const eventDate = new Date(e.CDW_DATE).getTime();
        return eventDate >= now;
    });

    eventsArray.sort((a, b) => {
        const dateA = new Date(a.CDW_DATE).getTime();
        const dateB = new Date(b.CDW_DATE).getTime();
        return dateA - dateB;
    });

    return (
        <div className="p-2 rounded-lg tile-animate flex flex-col h-full overflow-hidden w-full">
            <h3 className="text-lg md:text-xl lg:text-lg poppins-bold text-center text-blanc">{title}</h3>
            {eventsArray.length > 0 ? (
                <div className="p-1 rounded-lg overflow-y-auto flex-grow relative"> 
                    {eventsArray.map((e, index) => (
                        <CountdownComponent key={index} title={e.CDW_TITLE} info={e.CDW_INFO} date={e.CDW_DATE} />
                    ))}
                </div>
            ) : (
                <div className="px-2 lg:px-8 xl:px-12 flex flex-col justify-center items-center h-full">
                    <EmptyCalendarIcon className="size-14 lg:size-16 xl:size-14 2xl:size-20 fill-blanc" />
                    <p className="text-sm xl:text-lg mt-6 lg:mt-4 xl:mt-2 2xl:mt-6 text-center dark:text-blanc">Aucun évènement en approche sur les 3 prochains mois</p>
                </div>
            )}
        </div>
    );
};

export default CountdownTile;