import React, { useState, useEffect } from 'react';

// UTILS
import iconMap from '../../utils/iconMap';

function Header({ toggleSidebar, isSidebarCollapsed }) {
    const [announcementSearchTerm, setAnnouncementSearchTerm] = useState('');
    const [isScrolled, setIsScrolled] = useState(false);

    const MenuBurgerIcon = iconMap.menuBurger;
    const SearchIcon = iconMap.search;
    const CrossIcon = iconMap.cross;
    
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="flex items-center p-4 bg-white shadow-md">
            {/* Menu burger */}
            <button onClick={toggleSidebar} className="mr-4 p-3 rounded-full hover:bg-admin-header-menu-burger-hoover transition-colors duration-300">
                <MenuBurgerIcon className="w-6 h-6 fill-blanc" />
            </button>
            {/* Search bar */}
            <div className="flex flex-1 items-center bg-admin-card-background h-10 p-2 rounded-full shadow-sm border border-blanc-15">
                <SearchIcon className="w-6 h-6 fill-blanc mx-2" />
                <input
                    type="text"
                    placeholder="Recherche"
                    className="flex-1 rounded-5 px-5 bg-transparent poppins-semibold text-blanc focus:outline-none"
                    value={announcementSearchTerm}
                    onChange={(e) => setAnnouncementSearchTerm(e.target.value)}
                />
                {announcementSearchTerm && (
                    <button
                        className="mx-4"
                        onClick={() => setAnnouncementSearchTerm('')}
                    >
                        <CrossIcon className="w-6 h-6 fill-blanc" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Header;