import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// CONTEXT
import { AuthProvider } from './context/AuthContext';
import { ToastProvider } from './context/ToastContext';

// PAGES
import Home from './pages/home';
import Admin from './pages/admin';
import Error from './pages/error';

// COMPONENTS
import ProtectedRoute from './components/protectedRoute';

// UTILS
import checkForUpdates from './utils/checkForUpdates';

function App() {
    checkForUpdates();
    
    return (
        <AuthProvider>
            <ToastProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/admin/*" element={
                            <ProtectedRoute>
                                <Admin />
                            </ProtectedRoute>
                        } />
                        <Route path="/error" element={<Error />} />
                    </Routes>
                </Router>
            </ToastProvider>
        </AuthProvider>
    );
}

export default App;