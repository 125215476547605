import React, { useState, useContext } from 'react';

// CONTEXT
import { AuthContext } from '../context/AuthContext';

// ICONS
import { ReactComponent as EyeIcon } from '../assets/icons/eye.svg';
import { ReactComponent as EyeCrossedIcon } from '../assets/icons/eye-crossed.svg';

function AuthenticationForm() {
    const [localEmail, setLocalEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { login } = useContext(AuthContext);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        const success = await login(localEmail, password);
        if (success[1] === "nok") {
            setErrorMessage('Email ou mot de passe incorrect.');
        } else if (success[1] === "error") {
            setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
        }
    };

    return (
        <div className="w-full sm:w-96 mx-auto bg-blanc dark:bg-bleu rounded-lg shadow-lg p-8 mt-10">
            <form onSubmit={handleSubmit} className="space-y-6">
                <h2 className="text-2xl poppins-bold text-center text-bleu dark:text-blanc">Connexion</h2>
                <div className="space-y-2">
                    <label className="block poppins-medium text-bleu dark:text-blanc">
                        Email
                    </label>
                    <input
                        type="email"
                        value={localEmail}
                        onChange={(e) => setLocalEmail(e.target.value)}
                        required
                        className="w-full h-11 px-3 py-2 border border-gris rounded-lg shadow-sm focus:outline-none focus:border-bleu focus:dark:border-blanc text-bleu appearance-none"
                        placeholder="Entrez votre email"
                    />
                </div>
                <div className="space-y-2 relative">
                    <label className="block poppins-medium text-bleu dark:text-blanc">
                        Mot de passe
                    </label>
                    <input
                        key={showPassword ? 'text' : 'password'}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="w-full h-11 px-3 py-2 border border-gris rounded-lg shadow-sm focus:outline-none focus:border-bleu focus:dark:border-blanc text-bleu appearance-none"
                        placeholder="Entrez votre mot de passe"
                    />
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute inset-y-0 right-0 px-3 flex items-center pt-6"
                    >
                        {showPassword ? (
                            <EyeCrossedIcon className="w-6 h-6 fill-bleu" />
                        ) : (
                            <EyeIcon className="w-6 h-6 fill-bleu" />
                        )}
                    </button>
                </div>
                {errorMessage && (
                    <p className="text-rouge poppins-regular">{errorMessage}</p>
                )}
                <div>
                    <button
                        type="submit"
                        className="w-full bg-bleu hover:bg-emeraude text-blanc poppins-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                    >
                        Se connecter
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AuthenticationForm;