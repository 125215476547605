import React, { useState, useEffect } from 'react';

// HOOKS
import { useSocket } from '../../hooks/useSocket';

function Statistics() {
    const [connectedUsers, setConnectedUsers] = useState({ count: 0, emails: [] });
    const socket = useSocket();

    useEffect(() => {
        if (socket) {
            socket.on('update_user_count', (data) => {
                setConnectedUsers({ count: data.count, emails: data.emails });
            });

            return () => {
                socket.off('update_user_count');
            };
        }
    }, [socket]);

    const handleGoHome = () => {
        window.location.href = '/';
    };

    return (
        <div className="min-h-screen bg-blanc dark:bg-noir text-noir dark:text-blanc flex flex-col items-center py-10 px-5 space-y-8">
            <h1 className="text-4xl poppins-bold">Statistiques</h1>

            <button
                onClick={handleGoHome}
                className="px-4 py-2 bg-bleu text-blanc dark:text-noir rounded-md shadow-sm hover:bg-bleu-90 focus:outline-none"
            >
                Retour à l'accueil
            </button>

            {/* Compteur principal */}
            <div className="w-full md:w-1/2 lg:w-1/3 p-8 bg-bleu text-blanc dark:text-noir rounded-xl shadow-lg flex flex-col items-center">
                <h2 className="text-2xl poppins-semibold mb-2">Personnes connectées</h2>
                <p className="text-6xl font-SDProBold">{connectedUsers.count}</p>
            </div>

            {/* Détails des utilisateurs connectés */}
            <div className="w-full md:w-3/4 lg:w-1/2 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
                <h3 className="text-2xl poppins-semibold text-center text-noir dark:text-blanc mb-4">Utilisateurs connectés</h3>
                {connectedUsers.emails.length > 0 ? (
                    <ul className="space-y-2">
                        {connectedUsers.emails.map((email, index) => (
                            <li key={index} className="text-lg text-noir dark:text-blanc p-3 bg-gris dark:bg-gris-70 rounded-md shadow-sm">
                                {email}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-center text-lg">Aucun utilisateur connecté</p>
                )}
            </div>
        </div>
    );
}

export default Statistics;