import React, { useContext, useMemo } from 'react';

// CONTEXT
import { AuthContext } from '../context/AuthContext';

// HOOKS
import useUserData from '../hooks/useUserData';

// COMPONENTS
import Loader from '../components/loaderComponent';
import AuthenticationForm from '../components/authenticationFormComponent';
import Header from '../components/headerComponent';
import Grid from '../components/gridComponent';
import InfoBubble from '../components/infoBubbleComponent';

// UTILS
import { MapPreferencesToTiles } from '../utils/mapPreferencesToTiles';
import fakeTiles from '../utils/fakeTiles';

function Home() {
    const { authenticated, email, isInternal, isExternal, isAdmin, loading: authLoading } = useContext(AuthContext);
    const { userADInfo, userBDDInfo, preferences, loading: userDataLoading } = useUserData(email, isInternal, isExternal, isAdmin);

    const loading = authLoading || userDataLoading;

    const tiles = useMemo(() => {
        if (authenticated && preferences) {
            return MapPreferencesToTiles(preferences);
        }
        return fakeTiles;
    }, [authenticated, preferences]);

    return (
        <div className="bg-blanc dark:bg-noir text-blanc dark:text-noir">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Header user={userBDDInfo && userADInfo ? { userBDDInfo, userADInfo } : null} />
                    <div className="flex-grow flex justify-center items-center p-4 pt-1 relative">
                        <div className={`relative w-full h-full flex justify-center items-center ${!authenticated ? 'blur-sm' : ''}`}>
                            <Grid user={userBDDInfo && userADInfo ? { userBDDInfo, userADInfo } : null} tiles={tiles} />
                        </div>
                        {!authenticated && (
                            <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center max-h-[80vh] max-w-md mx-auto">
                                <AuthenticationForm />
                            </div>
                        )}
                    </div>
                    <InfoBubble />
                </>
            )}
        </div>
    );
}

export default Home;