import { useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_API_URL);

export function useSocket() {
    const { email } = useContext(AuthContext);

    useEffect(() => {
        // Confirmation de la connexion
        socket.on('connected', (data) => {
            // ...
        });

        if (email) {
            socket.emit('user_connected', email);
        }

        const handleUserCountUpdate = (data) => {
            // ...
        };

        socket.on('update_user_count', handleUserCountUpdate);

        return () => {
            socket.off('connected');
            socket.off('update_user_count', handleUserCountUpdate);
        };
    }, [email]);

    return socket;
}