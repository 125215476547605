import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Error = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { title = "Erreur", message = "Une erreur s'est produite." } = location.state || {};

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-red-100">
            <h1 className="text-3xl poppins-bold text-red-500 mb-4">{title}</h1>
            <p className="text-lg mb-4">{message}</p>
            <button 
                onClick={handleGoHome}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
                Retour à l'accueil
            </button>
        </div>
    );
};

export default Error;
