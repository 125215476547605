import React from 'react';

// UTILS
import iconMap from '../utils/iconMap';

function Modal({ children, onClose }) {
    const CrossSmallIcon = iconMap.crossSmall;

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div 
            className="fixed inset-0 z-50 flex items-center justify-center bg-noir bg-opacity-90"
            onClick={handleBackgroundClick}
        >
            <div className="text-blanc rounded-md p-6 relative">
                <CrossSmallIcon
                    className="absolute w-10 h-10 top-2 right-2 fill-blanc hover:fill-rouge cursor-pointer"
                    onClick={onClose}
                />
                {children}
            </div>
        </div>
    );
}

export default Modal;