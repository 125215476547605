import * as users from '../api/userApi';

export async function getUserADInfo(email) {
    try {
        const response = await users.getUserInfo(email);
        return response;
    } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur :', error);
        throw new Error('Erreur lors de la récupération des données utilisateur.');
    }
}

export async function getUserBDDInfo(email) {
    try {
        const response = await users.getUser(email);
        return response;
    } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur :', error);
        throw new Error('Erreur lors de la récupération des données utilisateur.');
    }
}

export async function insertUser(email, agency, is_external, is_admin, browser) {
    try {
        const response = await users.insertUser(email, agency, is_external, is_admin, browser);
        return response;
    } catch (error) {
        console.error('Erreur lors de l\'insertion de l\'utilisateur :', error);
        throw new Error('Erreur lors de l\'insertion de l\'utilisateur.');
    }
}

export async function updateUser(emailaddress, fieldsToUpdate) {
    try {
        const response = await users.updateUser(emailaddress, fieldsToUpdate);
        return response;
    } catch (error) {
        console.error('Erreur lors de la mise à jour de l\'utilisateur :', error);
        throw new Error('Erreur lors de la mise à jour de l\'utilisateur.');
    }
}