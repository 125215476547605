import * as statistic from '../api/statisticApi';

// Fonction pour insérer une statistique de recherche
export async function insertSearchStat(userId, browser) {
    try {
        const response = await statistic.insertSearchStat(userId, browser);
        return response;
    } catch (error) {
        console.error('Erreur lors de l\'insertion de la statistique de recherche :', error);
        throw new Error('Erreur lors de l\'insertion de la statistique de recherche.');
    }
}

// Fonction pour insérer une statistique de tuile
export async function insertTileStat(userId, tileId) {
    try {
        const response = await statistic.insertTileStat(userId, tileId);
        return response;
    } catch (error) {
        console.error('Erreur lors de l\'insertion de la statistique de tuile :', error);
        throw new Error('Erreur lors de l\'insertion de la statistique de tuile.');
    }
}

// Fonction pour insérer une statistique de connexion
export async function insertConnectionStat(userId) {
    try {
        const response = await statistic.insertConnectionStat(userId);
        return response;
    } catch (error) {
        console.error('Erreur lors de l\'insertion de la statistique de connexion :', error);
        throw new Error('Erreur lors de l\'insertion de la statistique de connexion.');
    }
}