import React, { useState, useEffect } from 'react';

// SERVICES
import { getAllNotifs, deleteNotif } from '../../../services/notificaitonService';

// COMPONENTS
import Breadcrumb from '../../../components/administration/breadcrumbComponent';

// UTILS
import iconMap from '../../../utils/iconMap';

function HistoryAnnouncements() {
    const [announcements, setAnnouncements] = useState([]);
    const [announcementSortOrder, setAnnouncementSortOrder] = useState('desc');

    const TrashIcon = iconMap.trash;

    // Récupération des annonces
    const fetchNotifications = async () => {
        try {
            const response = await getAllNotifs();
            setAnnouncements(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des notifications :', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    // Suppression d'une annonce
    const handleDeleteAnnouncement = async (notif_id) => {
        try {
            await deleteNotif(notif_id);
            await fetchNotifications();
        } catch (error) {
            console.error('Erreur lors de la suppression de l’annonce :', error);
        }
    };

    // Filtrer et trier les annonces
    const filteredAnnouncements = announcements
        .sort((a, b) => {
            const dateA = new Date(a.NTF_DATE);
            const dateB = new Date(b.NTF_DATE);
            return announcementSortOrder === 'desc' ? dateB - dateA : dateA - dateB;
        });

    // Inverser l'ordre de tri
    const toggleAnnouncementSortOrder = () => {
        setAnnouncementSortOrder(announcementSortOrder === 'desc' ? 'asc' : 'desc');
    };

    return (
        <div>
            <Breadcrumb iconName={'megaphone'} title={'Annonces'} currentPage={'Historique'} />
            <div className="max-w-7xl mx-auto space-y-8">
                {/* Annonces */}
                <section className="bg-admin-card-background p-6 rounded-lg shadow-sm">
                    {/* Tri */}
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
                        <button
                            className="bg-gris-30 hover:bg-gris-50 text-white font-bold py-2 px-4 rounded"
                            onClick={toggleAnnouncementSortOrder}
                        >
                            Trier plus {announcementSortOrder === 'desc' ? 'ancien' : 'récent'}
                        </button>
                    </div>
                    {filteredAnnouncements.length === 0 ? (
                        <p className="text-gris">Aucune annonce trouvée.</p>
                    ) : (
                        <div className="space-y-4">
                            {filteredAnnouncements.map((notif) => (
                                <div
                                    key={notif.NTF_ID}
                                    className="bg-noir-50 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow flex justify-between items-start"
                                >
                                    <div>
                                        <h3 className="poppins-semibold text-lg">{notif.NTF_CONTENT}...</h3>
                                        <p className="text-sm text-gris mt-1">
                                            Par : <span className="poppins-medium">{notif.NTF_AUTHOR}</span>
                                            {' • '}
                                            {new Date(notif.NTF_DATE).toLocaleDateString('fr-FR')}
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-end">
                                        {notif.NTF_URL && (
                                            <a
                                                href={notif.NTF_URL}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="bg-emeraude hover:bg-emeraude-700 text-white font-bold py-2 px-4 rounded mb-2"
                                            >
                                                Accéder à l'annonce
                                            </a>
                                        )}
                                        <button
                                            className="text-rouge hover:text-red-700"
                                            onClick={() => handleDeleteAnnouncement(notif.NTF_ID)}
                                        >
                                            <TrashIcon className="w-6 h-6 fill-rouge" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
}

export default HistoryAnnouncements;