import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// CONTEXT
import { AuthContext } from '../context/AuthContext';

// COMPONENTS
import Loader from './loaderComponent';

function ProtectedRoute({ children }) {
    const navigate = useNavigate();
    const { authenticated, isAdmin, loading } = useContext(AuthContext);

    if (loading) {
        return <Loader />;
    }

    if (!authenticated || !isAdmin) {
        navigate('/error', { state: { title: "Accès Refusé", message: "Vous n'avez pas les droits nécessaires pour accéder à cette page." } });
        return null;
    }

    return children;
}

export default ProtectedRoute;