import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// UTILS
import iconMap from '../../utils/iconMap';

// IMAGES
import LogoMobile from '../../assets/images/Pastille_AI_RVB_Orange.png';

const AngleSmallIcon = iconMap.angleSmall;

const HomeIcon = iconMap.home;
const UserIcon = iconMap.user;
const MegaphoneIcon = iconMap.megaphone;
const ExitAltIcon = iconMap.exitAlt;

const menuItems = [
    {
        name: 'Dashboard',
        to: '/admin/dashboard',
        icon: HomeIcon,
        subMenu: [
            { name: 'Aperçu', to: '/admin/dashboard/overview' },
            { name: 'Analyses', to: '/admin/dashboard/analytics' },
        ],
    },
    {
        name: 'Utilisateurs',
        to: '/admin/users',
        icon: UserIcon,
        subMenu: [
            { name: 'Liste', to: '/admin/users/list' },
            { name: 'Permissions', to: '/admin/users/permissions' },
        ],
    },
    {
        name: 'Annonces',
        to: '/admin/announcements',
        icon: MegaphoneIcon,
        subMenu: [
            { name: 'Historique', to: '/admin/announcements/history' },
            { name: 'Gestion', to: '/admin/announcements/management' },
        ],
    },
];

const SidebarMenu = ({ isCollapsed, setIsCollapsed }) => {
    const [openMenu, setOpenMenu] = useState(null);
    const [previousOpenMenu, setPreviousOpenMenu] = useState(null);
    const [isHovered, setIsHovered] = useState(false);

    // Nouveaux états pour gérer les animations
    const [isCollapsing, setIsCollapsing] = useState(false);
    const [isExpanding, setIsExpanding] = useState(false);
    const [showText, setShowText] = useState(!isCollapsed);

    const toggleMenu = (index) => {
        setOpenMenu((prev) => (prev === index ? null : index));
    };

    const handleMouseEnter = () => {
        if (isCollapsed) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        if (isCollapsed) {
            setIsHovered(false);
        }
    };

    useEffect(() => {
        let timeoutId;

        if (isCollapsed) {
            setIsCollapsing(true);
            setShowText(false);

            timeoutId = setTimeout(() => {
                setIsCollapsing(false);
            }, 1000);

        } else {
            setIsExpanding(true);

            timeoutId = setTimeout(() => {
                setShowText(true);
                setIsExpanding(false);
            }, 500);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isCollapsed]);

    useEffect(() => {
        let timeoutId;

        if (isCollapsed) {
            if (isHovered) {
                setShowText(true);
            } else {
                setShowText(false);
            }
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isHovered, isCollapsed]);

    return (
        <div
            className={`relative w-20
                ${isCollapsed && !isHovered ? 'w-20' : 'w-64'}
                ${
                    isCollapsing && !isHovered
                        ? 'transition-width duration-500 delay-500'
                        : isExpanding && !isHovered
                        ? 'transition-width duration-500'
                        : ''
                }
                ${isHovered && 'fixed z-40'}
                transition-all
                border-r border-blanc-15
            `}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="bg-admin-menu-background p-4 h-full flex flex-col">
                <div className="flex items-center justify-center mb-6">
                    <img src={LogoMobile} alt="altitudeInfra Logo Mobile" className="h-10" />
                    {(!isCollapsed || isHovered) && (
                        <h2
                            className={`text-2xl poppins-bold ml-5 ${
                                showText
                                    ? 'opacity-100 transition-opacity duration-500'
                                    : 'opacity-0 transition-opacity duration-500'
                            }`}
                        >
                            Panel
                        </h2>
                    )}
                </div>
                <div className="flex-grow">
                    <nav className="space-y-4">
                        {menuItems.map((item, index) => (
                            <div key={index}>
                                {/* Menu principal */}
                                <div
                                    className={`flex items-center ${
                                        !isCollapsed || isHovered
                                            ? 'justify-between px-4'
                                            : 'justify-center'
                                    } text-lg poppins-medium py-2 rounded-lg cursor-pointer ${
                                        openMenu === index
                                            ? 'bg-admin-menu-active text-admin-menu-text-active'
                                            : 'bg-admin-menu-background text-admin-menu-text-inactive'
                                    } hover:bg-admin-menu-active hover:text-admin-menu-text-active group`}
                                    onClick={() => toggleMenu(index)}
                                >
                                    <div className="flex items-center">
                                        <item.icon
                                            className={`w-6 h-6 ${
                                                !isCollapsed || isHovered
                                                    ? 'mr-4'
                                                    : ''
                                            } ${
                                                openMenu === index
                                                    ? 'fill-admin-menu-text-active'
                                                    : 'fill-admin-menu-text-inactive'
                                            } group-hover:fill-admin-menu-text-active`}
                                        />
                                        {(!isCollapsed || isHovered) && (
                                            <span
                                                className={`group-hover:text-admin-menu-text-active ${
                                                    showText
                                                        ? 'opacity-100'
                                                        : 'opacity-0'
                                                } transition-opacity duration-500`}
                                            >
                                                {item.name}
                                            </span>
                                        )}
                                    </div>
                                    {/* Flèche rotative */}
                                    {(!isCollapsed || isHovered) && (
                                        <AngleSmallIcon
                                            className={`w-6 h-6 transition-transform duration-500 ${
                                                openMenu === index
                                                    ? '-rotate-180 fill-admin-menu-text-active'
                                                    : '-rotate-90 fill-admin-menu-text-inactive'
                                            } ${
                                                showText
                                                    ? 'opacity-100'
                                                    : 'opacity-0'
                                            } transition-opacity duration-500 group-hover:fill-admin-menu-text-active`}
                                        />
                                    )}
                                </div>

                                {/* Sous-menus */}
                                {(!isCollapsed || isHovered) && (
                                    <div
                                        className={`overflow-hidden transition-all duration-500 ${
                                            openMenu === index
                                                ? 'max-h-screen'
                                                : 'max-h-0'
                                        }`}
                                    >
                                        {item.subMenu.map((subItem, subIndex) => (
                                            <NavLink
                                                key={subIndex}
                                                to={subItem.to}
                                                className={({ isActive }) =>
                                                    `block text-lg poppins-medium px-2 py-1 mt-2 rounded-lg ${
                                                        isActive
                                                            ? 'bg-admin-menu-active text-admin-menu-text-active'
                                                            : 'text-admin-menu-text-inactive hover:bg-admin-menu-hover hover:text-admin-menu-text-active'
                                                    }`
                                                }
                                            >
                                                <span
                                                    className={`${
                                                        showText
                                                            ? 'opacity-100'
                                                            : 'opacity-0'
                                                    } transition-opacity duration-500 ml-8`}
                                                >
                                                    {subItem.name}
                                                </span>
                                            </NavLink>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </nav>
                </div>
                {/* Bouton 'Retour à l'accueil' */}
                <NavLink
                    to="/"
                    className={`flex items-center mt-4 py-2 text-lg poppins-medium rounded-lg ${
                        !isCollapsed || isHovered
                        ? 'justify-start px-4'
                        : 'justify-center'
                    } text-admin-menu-text-active hover:bg-admin-menu-hover hover:text-admin-menu-text-active group`}
                >
                    <ExitAltIcon
                        className={`w-6 h-6 ${
                        !isCollapsed || isHovered ? 'mr-4' : ''
                        } fill-admin-menu-text-inactive group-hover:fill-admin-menu-text-active`}
                    />
                    {(!isCollapsed || isHovered) && (
                        <span
                        className={`text-admin-menu-text-inactive group-hover:text-admin-menu-text-active ${
                            showText
                            ? 'opacity-100 transition-opacity duration-500'
                            : 'opacity-0 transition-opacity duration-500'
                        }`}
                        >
                        Accueil
                        </span>
                    )}
                </NavLink>
            </div>
        </div>
    );
};

export default SidebarMenu;