import apiClient from './api';

export const getUserInfo = (emailaddress) => apiClient.get(`/user/ad/info/${emailaddress}`);

export const getUser = (emailaddress) => apiClient.get(`/user/bdd/info/${emailaddress}`);

export const insertUser = (emailaddress, agency, is_external, is_admin, browser) => apiClient.post(`/user/info/insert`, { emailaddress, agency, is_external, is_admin, browser });

export const updateUser = (emailaddress, { agency, is_external, is_admin, browser, dark_mode, language, last_connection_at }) => {
    const data = {
        emailaddress, // toujours requis
    };

    // Ajouter uniquement les paramètres définis pour permettre la mise à jour partielle
    if (agency !== undefined) data.agency = agency;
    if (is_external !== undefined) data.is_external = is_external;
    if (is_admin !== undefined) data.is_admin = is_admin;
    if (browser !== undefined) data.browser = browser;
    if (dark_mode !== undefined) data.dark_mode = dark_mode;
    if (language !== undefined) data.language = language;
    if (last_connection_at !== undefined) data.last_connection_at = new Date(last_connection_at.toLocaleString());

    return apiClient.put(`/user/info/update`, data);
};