import React, { useState, useEffect } from 'react';

function AnimatedNumber({ value }) {
  const [displayValue, setDisplayValue] = useState(value);
  const [prevValue, setPrevValue] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (value !== displayValue) {
      setPrevValue(displayValue);
      setDisplayValue(value);
      setIsAnimating(true);
      const timer = setTimeout(() => {
        setPrevValue(null);
        setIsAnimating(false);
      }, 500); // Durée de l'animation en millisecondes
      return () => clearTimeout(timer);
    }
  }, [value]);

  return (
    <div className="relative h-full w-full">
      {prevValue !== null && isAnimating && (
        <div className="absolute inset-0 animate-slide-up-out-countdown flex items-center justify-center">
          {prevValue}
        </div>
      )}
      <div
        className={`${
          isAnimating ? 'animate-slide-up-in-countdown' : ''
        } flex items-center justify-center h-full`}
      >
        {displayValue}
      </div>
    </div>
  );
}

export default AnimatedNumber;