import React, { useState, useEffect } from 'react';

// COMPONENTS
import AreaChart from '../../../../components/administration/charts/areaChart';

// SERVICES
import { getStatNewUser } from '../../../../services/administration/analysisService';

const NewUserChartContainer = () => {
  const [chartData, setChartData] = useState({
    series: [],
    categories: [],
  });
  
  // Mois actuel par défaut
  const currentMonth = new Date().toISOString().slice(0, 7).replace('-', '');

  const [dateFilter, setDateFilter] = useState(currentMonth);

  const fetchData = async () => {
    try {
      const response = await getStatNewUser(dateFilter);
      const data = response.data;
      
      let categories = [];
      let seriesData = [];
      
      if (data.length > 0) {
        // Déterminer le type d'agrégation
        if (data[0]?.DayDate) {
          categories = data.map(item => item.DayDate);
          seriesData = data.map(item => item.TOTAL);
        } else if (data[0]?.MonthYear) {
          categories = data.map(item => item.MonthYear);
          seriesData = data.map(item => item.TOTAL);
        } else if (data[0]?.HourDate) {
          categories = data.map(item => item.HourDate);
          seriesData = data.map(item => item.TOTAL);
        }
      }

      setChartData({
        series: [{ name: 'Nouveaux utilisateurs', data: seriesData }],
        categories
      });
    } catch (error) {
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateFilter]);

  return (
    <div>
      <h2>Nombre de nouveaux utilisateurs</h2>
      <button onClick={() => setDateFilter('2024')}>Filtrer par Année</button>
      <button onClick={() => setDateFilter('202412')}>Filtrer par Mois</button>
      <button onClick={() => setDateFilter('20241206')}>Filtrer par Jour</button>
      
      <AreaChart 
          series={[{ name: "Net Sales", data: [4, 10, 25, 12, 25, 18, 40, 22, 7] }]}
          categories={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']}
        />
    </div>
  );
};

export default NewUserChartContainer;