import React, { useState, useEffect } from 'react';

// UTILS
import iconMap from '../../utils/iconMap';
import TooltipComponent from '../tooltipComponent';

const InformationTile = ({ icon, value, unit, hover }) => {
    const IconComponent = iconMap[icon];
    const [currentValue, setCurrentValue] = useState(0);

    useEffect(() => {
        let startValue = 0;
        const endValue = parseInt(value, 10);
        const duration = 2000;
        const startTime = performance.now();

        const updateCounter = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);
            const easeOutProgress = 1 - Math.pow(1 - progress, 4);

            const animatedValue = Math.floor(startValue + (endValue - startValue) * easeOutProgress);

            setCurrentValue(animatedValue);

            if (progress < 1) {
                requestAnimationFrame(updateCounter);
            }
        };

        requestAnimationFrame(updateCounter);

        return () => cancelAnimationFrame(updateCounter);
    }, [value]);

    return (        
        <TooltipComponent title={hover} placement="bottom">
            <div className="p-4 text-center flex flex-col items-center justify-center space-y-1">
                {IconComponent && (
                    <div>
                        <IconComponent className="w-6 h-6 fill-current" />
                    </div>
                )}
                <h3 className="text-base poppins-bold text-blanc">
                    {currentValue.toLocaleString('fr-FR')}
                </h3>
                <p className="text-xs poppins-regular text-blanc">
                    {unit}
                </p>
            </div>
        </TooltipComponent>
    );
};

export default InformationTile;