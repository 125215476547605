export function MapPreferencesToTiles(preferences) {
    return preferences.map((pref, index) => {
        let tilData = {};
        try {
            tilData = JSON.parse(pref.TIL_DATA);
        } catch (error) {
            console.error('Erreur lors de l\'analyse de TIL_DATA :', error);
        }

        const result = {
            id: pref.TIL_ID,
            position_x_desktop: pref.PRF_POSITION_X_DESKTOP,
            position_y_desktop: pref.PRF_POSITION_Y_DESKTOP,
            width_desktop: pref.PRF_WIDTH_DESKTOP,
            height_desktop: pref.PRF_HEIGHT_DESKTOP,
            position_x_mobile: pref.PRF_POSITION_X_MOBILE,
            position_y_mobile: pref.PRF_POSITION_Y_MOBILE,
            width_mobile: pref.PRF_WIDTH_MOBILE,
            height_mobile: pref.PRF_HEIGHT_MOBILE,
            color: pref.TCO_HEXA,
            type: pref.TYP_NAME,
            data: tilData,
            notification: pref.NOTIFICATIONS || null,
            evenement: pref.EVENEMENTS || null,
            isOptional: pref.TIL_IS_OPTIONAL,
            createdAt: pref.TIL_CREATED_AT,
        };
        
        return result;
    });
}