import * as tile from '../api/tileApi';

export async function getAllColors() {
    try {
        const response = await tile.getAllColors();
        return response;
    } catch (error) {
        console.error('Erreur lors de la récupération des couleurs des tuiles :', error);
        throw new Error('Erreur lors de la récupération des couleurs des tuiles.');
    }
}

export async function upsertUserPreferences(userId, preferences) {
    try {
        const response = await tile.upsertUserPreferences(userId, preferences);
        return response;
    } catch (error) {
        console.error('Erreur lors de la mise à jour des préférences utilisateur :', error);
        throw new Error('Erreur lors de la mise à jour des préférences utilisateur.');
    }
}

export async function getDefaultPreferences(status) {
    try {
        const response = await tile.getDefaultPreferences(status);
        return response;
    } catch (error) {
        console.error('Erreur lors de la récupération des préférences par défaut :', error);
        throw new Error('Erreur lors de la récupération des préférences par défaut.');
    }
}

export async function getUserPreferences(userId) {
    try {
        const response = await tile.getUserPreferences(userId);
        return response;
    } catch (error) {
        console.error('Erreur lors de la récupération des préférences utilisateur :', error);
        throw new Error('Erreur lors de la récupération des préférences utilisateur.');
    }
}