import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// HOOKS
import { useSocket } from '../../hooks/useSocket';

// SERVICES
import { markAsRead as markNotificationAsRead } from '../../services/notificaitonService';

// UTILS
import iconMap from '../../utils/iconMap';

const NotificationTile = ({ user_id, title, url, notif, isPrivate, onPrivateClick }) => {
    const [displayedNotifications, setDisplayedNotifications] = useState([]);
    const [readNotifications, setReadNotifications] = useState(new Set());
    const socket = useSocket();
    const [, setTime] = useState(Date.now());

    const EmptyInBoxIcon = iconMap['emptyInBox'];

    useEffect(() => {
        if (notif) {
            try {
                const parsedNotif = JSON.parse(notif);
                const transformedNotif = parsedNotif.map(n => ({
                    id: n.NTF_ID,
                    content: n.NTF_CONTENT,
                    url: n.NTF_URL,
                    createdAt: n.NTF_DATE,
                    senderName: n.NTF_AUTHOR,
                    isRead: n.IS_READ
                }));
                
                transformedNotif.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                setDisplayedNotifications(transformedNotif);

                const readNotifSet = new Set(transformedNotif.filter(n => n.isRead).map(n => n.id));
                setReadNotifications(readNotifSet);
            } catch (error) {
                console.error('Failed to parse notif:', error);
            }
        }
    }, [notif]);

    useEffect(() => {
        if (!socket) {
            console.error("Socket n'est pas défini");
            return;
        }
    
        socket.on('data-received', (newNotification) => {
            if (!newNotification.notificationId) {
                console.error("Notification reçue sans ID:", newNotification);
                return;
            }
    
            const formattedNotification = {
                id: newNotification.notificationId,
                content: newNotification.content,
                url: newNotification.url,
                createdAt: newNotification.createdAt,
                senderName: newNotification.senderName,
                isRead: false
            };
    
            setDisplayedNotifications(prev => [formattedNotification, ...prev]);
        });
    
        return () => {
            socket.off('data-received');
        };
    }, [socket]);    

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                setTime(Date.now());
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const formatNotificationDate = (date) => {
        const now = new Date();
        const diffInMinutes = Math.floor((now - date) / 60000);

        if (diffInMinutes < 1) {
            return "il y a quelques secondes";
        } else if (diffInMinutes < 60) {
            return `il y a ${diffInMinutes} min`;
        } else if (diffInMinutes < 1440) {
            const hours = Math.floor(diffInMinutes / 60);
            return `il y a ${hours} h`;
        } else if (diffInMinutes < 10080) {
            return format(date, "EEEE HH:mm", { locale: fr });
        } else if (diffInMinutes < 43200) {
            return format(date, "EEE dd MMM", { locale: fr });
        } else {
            return format(date, "dd MMM yyyy", { locale: fr });
        }
    };

    const markAsRead = async (notificationId) => {
        if (!readNotifications.has(notificationId)) {
            try {
                await markNotificationAsRead(user_id, notificationId);
                setReadNotifications(prev => new Set([...prev, notificationId]));
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la notification:', error);
            }
        }
    };

    const markAllAsRead = async () => {
        try {
            const unreadNotifs = displayedNotifications.filter(n => !readNotifications.has(n.id));
            await Promise.all(unreadNotifs.map(n => markNotificationAsRead(user_id, n.id)));
            setReadNotifications(new Set(displayedNotifications.map(n => n.id)));
        } catch (error) {
            console.error('Erreur lors de la mise à jour des notifications:', error);
        }
    };

    return (
        <div className="w-full h-full p-4 rounded-lg overflow-hidden flex flex-col">
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-lg md:text-xl lg:text-lg poppins-bold text-center text-blanc"
            >
                {title}
            </a>

            {displayedNotifications.some(n => !readNotifications.has(n.id)) && (
                <button 
                    className="my-2 mr-2 px-4 py-1 rounded-md self-end text-sm border border-blanc text-blanc hover:bg-blanc hover:text-noir"
                    onClick={markAllAsRead}
                >
                    Tout marquer comme lu
                </button>
            )}

            <div className="p-2 rounded-lg overflow-y-auto flex-grow relative">
                {displayedNotifications.length > 0 ? (
                    displayedNotifications.map((notification, index) => (
                        <a 
                            key={notification.id}
                            href={notification.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="group relative block"
                            onClick={() => markAsRead(notification.id)}
                        >
                            <div 
                                className={
                                    `mt-5 p-3 max-h-24 rounded-lg
                                    bg-blanc-70 dark:bg-noir-70
                                    transform transition-transform duration-300 ease-out opacity-0 animate-fade-in
                                    ${!readNotifications.has(notification.id) ? 'border border-framboise-variant-1 text-noir dark:text-blanc' : 'text-noir-80 dark:text-blanc-80'}`
                                }
                                style={{ animationDelay: `${index * 0.2}s` }}
                            >
                                {!readNotifications.has(notification.id) && (
                                    <span className="absolute -top-4 right-2 w-20 h-4 bg-framboise-variant-1 rounded-t-md text-center text-xs text-blanc dark:text-blanc">
                                        Non lue
                                    </span>
                                )}
                                <div className="flex justify-between items-center mb-1">
                                    <h3 className={`text-xs ${!readNotifications.has(notification.id) ? 'poppins-extrabold' : 'poppins-bold'}`}>{notification.senderName}</h3>
                                    <span className="text-xs">
                                        {formatNotificationDate(new Date(notification.createdAt))}
                                    </span>
                                </div>
                                <p className={`text-xs line-clamp-2 max-h-14 overflow-hidden text-ellipsis break-words ${!readNotifications.has(notification.id) ? 'poppins-extrabold' : 'poppins-light'}`}>
                                    {notification.content}
                                </p>
                            </div>
                        </a>
                    ))
                ) : (
                    <div className="flex flex-col justify-center items-center h-full">
                        <EmptyInBoxIcon className="w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20 fill-current" />
                        <p className="text-sm md:text-lg lg:text-xl mt-6 text-center dark:text-blanc">Aucune notification</p>
                    </div>
                )}
            </div>
        </div>
    );
  };
  
  export default NotificationTile;