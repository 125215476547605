import React, { createContext, useState, useEffect, useContext } from 'react';
import { checkLocalCookie, checkSSO, login as authLogin, getSecurityGroups } from '../services/authService';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [email, setEmail] = useState(null);
    const [isInternal, setIsInternal] = useState(false);
    const [isExternal, setIsExternal] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [forceReload, setForceReload] = useState(false);

    useEffect(() => {
        const authenticateUser = async () => {
            try {
                let userEmail = await checkLocalCookie();
                if (!userEmail) {
                    userEmail = await checkSSO();
                }
                
                if (userEmail) {
                    setEmail(userEmail);
                    setAuthenticated(true);

                    // Attendre que fetchGroups termine avant de définir loading à false
                    const [userIsInternal, userIsExternal, userIsAdmin] = await fetchGroups(userEmail);
                    setIsInternal(userIsInternal);
                    setIsExternal(userIsExternal);
                    setIsAdmin(userIsAdmin);

                    // Vérifier si l'utilisateur a l'un des rôles requis
                    if (userIsInternal || userIsExternal || userIsAdmin) {
                        setAuthenticated(true);
                    } else {
                        setAuthenticated(false);
                    }
                } else {
                    setAuthenticated(false);
                }
            } catch (error) {
                console.error("Erreur lors de l'authentification de l'utilisateur :", error);
                setAuthenticated(false);
            } finally {
                setLoading(false);
            }
        };

        authenticateUser();
    }, [forceReload]);

    const fetchGroups = async (email) => {
        const internalGroup = process.env.REACT_APP_GROUP_SECU_INTERNE;
        const externalGroup = process.env.REACT_APP_GROUP_SECU_EXTERNE;
        const adminGroup = process.env.REACT_APP_GROUP_SECU_ADMIN;

        try {
            const groups = await getSecurityGroups(email);

            const userIsInternal = groups.some(group => group.displayName === internalGroup);
            const userIsExternal = groups.some(group => group.displayName === externalGroup);
            const userIsAdmin = groups.some(group => group.displayName === adminGroup);

            return [userIsInternal, userIsExternal, userIsAdmin];
        } catch (error) {
            console.error('Error while fetching groups : ', error);
            return [false, false, false];
        }
    };

    const login = async (email, password) => {
        try {
            const userEmail = await authLogin(email, password);
            if (userEmail && userEmail !== "nok") {
                const [userIsInternal, userIsExternal, userIsAdmin] = await fetchGroups(userEmail);

                setEmail(userEmail);
                setAuthenticated(userIsInternal || userIsExternal || userIsAdmin);
                setForceReload(prev => !prev);
                return [true, "ok"];
            } else {
                setAuthenticated(false);
                return [false, "nok"];
            }
        } catch (error) {
            console.error("Erreur lors de la connexion :", error);
            return [false, "error"];
        }
    };

    const logout = () => {
        // Implémentation de la déconnexion ici
        // Suppression du cookie
        setAuthenticated(false);
        setEmail(null);
        setIsInternal(false);
        setIsExternal(false);
        setIsAdmin(false);
        setForceReload(prev => !prev);
    };

    return (
        <AuthContext.Provider value={{ authenticated, email, isInternal, isExternal, isAdmin, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
}