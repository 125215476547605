// ToastContext.js
import React, { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMediaQuery } from 'react-responsive';

// COMPONENTS
import Toast from '../components/toastComponent';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
    const isMobile = useMediaQuery({ maxWidth: 640 });

    const addToast = (title, message, action, actionText, type) => {
        const id = uuidv4();
        setToasts((prevToasts) => {
            const newToasts = isMobile ? [] : prevToasts.length >= 3 ? prevToasts.slice(1) : prevToasts;
            return [...newToasts, { title, message, action, actionText, type, id }];
        });
    };

    const removeToast = (id) => {
        setToasts((prevToasts) =>
            prevToasts.filter((toast) => toast.id !== id)
        );
    };

    return (
        <ToastContext.Provider value={{ addToast }}>
        {children}
        <div className={`fixed ${isMobile ? 'top-0 left-0 right-0' : 'bottom-5 right-5'} flex flex-col gap-3 z-[9999]`}>
            {toasts.slice(-1).map((toast) => (
                <Toast
                    key={toast.id}
                    title={toast.title}
                    message={toast.message}
                    action={toast.action}
                    actionText={toast.actionText}
                    type={toast.type}
                    onClose={() => removeToast(toast.id)}
                />
            ))}
        </div>
        </ToastContext.Provider>
    );
};